export const getCookie = (cookie: string) => {
  const cookieValue = document.cookie
    .split('; ')
    .find((c) => c.startsWith(`${cookie}=`))
    ?.split('=')[1];

  return cookieValue;
};

export const setCookie = (cookie: string, value: string, maxAge?: number) => {
  document.cookie = `${cookie}=${value}; path=/; max-age=${
    maxAge ?? 60 * 60 * 24 * 7
  }`;
};

export const deleteCookie = (cookie: string) => {
  document.cookie = `${cookie}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
