import { Action, EventBus, Reaction } from '@bees-lite-web/core';
import { Subject } from 'rxjs';

export enum MELLIFERA_EVENT_TYPE {
  error = 'error',
  // save = 'save',
  navigation = 'navigation',
  get = 'get',
  response = 'response',
  resize = 'resize',
  action = 'action',
  tracking = 'tracking',
  reaction = 'reaction',
  connection = 'connection',
}

export let emmiter: Subject<Action<MELLIFERA_EVENT_TYPE> | Reaction>;
export const eventContext = new EventBus<MELLIFERA_EVENT_TYPE>(
  (sub) => (emmiter = sub)
);
