import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export class ResponseRenderHandler implements IEventHandler<KeeperPayload> {
  constructor(private dispatcher: Subject<KeeperPayload>) {}

  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType((e) => (e.parameters?.render?.length ?? 0) > 0),
      tap((e) => {
        this.dispatcher.next(e.parameters);
      })
    );
  }
}
