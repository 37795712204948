import { Action, IActionParams, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import history from '../../core/browserHistory';

const IGNORED_PARAMETERS = ['Hornet', 'skeleton', 'searchValue', 'palletView'];

interface IUpdatePageParametersParams {}

export class ActionsUpdatePageParametersHandler
  implements IEventHandler<IUpdatePageParametersParams>
{
  handler(
    source: Observable<Action<'action', IUpdatePageParametersParams>>
  ): Observable<Action<'action', IUpdatePageParametersParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.UPDATE_PAGE_PARAMETERS),
      tap(async (e: IActionParams<any, any>) => {
        const actionParameters = JSON.parse(JSON.stringify(e.parameters));
        actionParameters.keywords = actionParameters.searchValue;

        Object.keys(actionParameters).forEach((paramKey) => {
          IGNORED_PARAMETERS.forEach((paramKeyToIgnore) => {
            if (paramKey.match(new RegExp(`${paramKeyToIgnore}`, 'g'))) {
              delete actionParameters[paramKey]
             }
          })
        });

        const parametersToUpdate = `?${new URLSearchParams(actionParameters).toString()}`
        history.replace(parametersToUpdate);
      })
    );
  }
}
