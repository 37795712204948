import { Action, IActionParams, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MELLIFERA_EVENT_TYPE, emmiter } from '../../core/eventContext';

export class SearchHandler implements IEventHandler {
  handler(
    source: Observable<Action<'search', any>>
  ): Observable<Action<'search'>> {
    return source.pipe(
      tap(async (e: IActionParams<any, any>) => {
          emmiter.next(
            new Action({
              type: MELLIFERA_EVENT_TYPE.navigation,
              url: '/search',
              eventTrigger: 'search',
              parameters: {
                replaceProcessedUrl: e.url && e.url.replace('{keywords}', e.parameters.searchValue )
              }
            })
          );
      }),
    );
  }
}
