import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { StorageAbstract } from '../../core/storage/storage.abstract';

export interface ISaveSelectedPocParams {
  id?: string;
  pocId?: string;
  poc?: string;
  skeleton?: string;
}

export class ActionsSaveSelectedPocHandler
  implements IEventHandler<ISaveSelectedPocParams>
{
  constructor(private store: StorageAbstract) {}

  handler(
    source: Observable<Action<'action', ISaveSelectedPocParams>>
  ): Observable<Action<'action', ISaveSelectedPocParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.SAVE_SELECTED_POC),
      tap(console.log),
      concatMap(async (e) => {
        const auth = await this.store.get('auth');
        await this.store.set('auth', {
          ...auth,
          activePoc: {
            id: e.parameters!.id ?? e.parameters!.pocId,
          },
        });
        window.location.reload();
        return e;
      })
    );
  }
}
