import {
  Action,
  IActionParams,
  IEventHandler,
  ofType,
} from '@bees-lite-web/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { Observable } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';
import { sendAnalytics } from '../../../utils/sendAnalytics';
import history from '../../core/browserHistory';
import { navigationExtraParams } from '../../core/navigationExtraParams.subject';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { clearAllContextData } from '../../store/Events';

export interface INavigationParams {
  url: string;
  analyticsData?: any;
}

const IGNORED_PARAMETERS = ['skeleton'];

export class NavigationHandler implements IEventHandler<INavigationParams> {
  constructor(
    private store: StorageAbstract,
    private analytics?: AnalyticsBrowser
  ) {}

  handler(
    source: Observable<Action<'navigation', INavigationParams>>
  ): Observable<Action<'navigation', INavigationParams>> {
    return source.pipe(
      ofType((action) => !action.url?.startsWith('action:')),
      exhaustMap(async (e: IActionParams<any, any>) => {
        const [country, language] = await Promise.all([
          this.store.get('country') || 'US',
          this.store.get('language'),
        ]);

        const { pathname } = history.location;
        const [url, queryString] = e.url?.split('?') ?? [];
        const windowPath: string = pathname.substring(
          pathname.length,
          pathname.lastIndexOf('/')
        );

        const initialPublicConfig = localStorage.getItem('initialPublicConfig');
        const lastJourney = localStorage.getItem('lastJourney');
        const hasToken = localStorage.getItem('token');

        if (windowPath === url) {
          if (initialPublicConfig && !hasToken && !lastJourney) {
            const path = `/${country}/${language}${JSON.parse(
              initialPublicConfig
            )}`;

            history.push(path);
          }
          return e;
        }

        const queryParams = new URLSearchParams(queryString);

        if (Object.keys(e.parameters || {}).length !== 0) {
          navigationExtraParams.next(e.parameters);
          Object.entries(e.parameters)
            .filter(([key, value]) => !IGNORED_PARAMETERS.includes(key))
            .forEach(([key, value]) => queryParams.set(key, value as string));
        }

        let lastRoutePath: string = history.location.pathname.substring(
          pathname.length,
          pathname.lastIndexOf('/')
        );

        if (lastRoutePath === '/') {
          const currentRoute =
            ((await this.store.get('currentRoute')) as string) || '/';

          lastRoutePath = currentRoute.substring(
            0,
            currentRoute.lastIndexOf('/')
          );
        }

        await this.store.set('lastRoute', lastRoutePath);
        await this.store.set('currentRoute', e.url);

        const formatedQueryParams =
          queryParams.toString() !== '' ? '?' + queryParams : '';
        const updatedLocation = `/${country}/${language}${url}${formatedQueryParams}`;

        clearAllContextData();

        history.push(updatedLocation);

        return e;
      }),
      tap(async (e) => {
        if (e.parameters && this.analytics) {
          await sendAnalytics(e.parameters, this.analytics);
        }
      })
    );
  }
}
