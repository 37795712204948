import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import browserHistory from '../../core/browserHistory';
import { clearAllContextData } from '../../store/Events';

export interface IGoBackParams {}

export class ActionsGoBackHandler implements IEventHandler<IGoBackParams> {
  handler(
    source: Observable<Action<'action', IGoBackParams>>
  ): Observable<Action<'action', IGoBackParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.GO_BACK),
      concatMap(async (e) => {
        browserHistory.goBack();

        clearAllContextData();

        return e;
      })
    );
  }
}
