import { BaseContext } from '../../../../../../../libs/core/src';

const setupKeepedStates = (_contextData: BaseContext[]) =>
  new Promise((resolve) => {
    const keepedStates: BaseContext[] = JSON.parse(
      (localStorage.getItem('keepedStates') as any) || '[]'
    );
    if (keepedStates.length) {
      for (const keepedState of keepedStates) {
        if (
          !_contextData.find((e) => e.id === keepedState.id || !e.keepState)
        ) {
          _contextData.push(
            new BaseContext(
              keepedState.id,
              keepedState.data,
              keepedState.keepState
            )
          );
        }
      }
      resolve(true);
    } else {
      resolve(true);
    }
  });

export default setupKeepedStates;
