import { Throwable } from '../../core/interfaces/maybe.type';
import { KeeperPayload } from './keeper.payload.interface';

export abstract class KeeperAbstractService {
  abstract patch(
    url: string,
    parameters: any
  ): Promise<Throwable<KeeperPayload>>;
  abstract post(
    url: string,
    parameters: any
  ): Promise<Throwable<KeeperPayload>>;
  abstract get(url: string | undefined): Promise<Throwable<KeeperPayload>>;
  abstract delete(url: string): Promise<Throwable<KeeperPayload>>;
  abstract configure(): Promise<Throwable<KeeperPayload>>;
  abstract getOffilinePages(): Promise<Throwable<KeeperPayload>>;
  abstract login(
    user: string,
    password: string
  ): Promise<Throwable<KeeperPayload>>;
  abstract setHeaders(headers: Record<string, string>): void;
}
