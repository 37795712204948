import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { StorageAbstract } from '../core/storage/storage.abstract';

interface IOfflineContext {
  isOnline: boolean;
  offlinePage?: any;
}

interface IOfflineProviderProps {
  children: ReactNode;
  storage: StorageAbstract;
}

const OfflineContext = createContext<IOfflineContext | undefined>(undefined);

const OfflineProvider = ({ children, storage }: IOfflineProviderProps) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [offlinePage, setOfflinePage] = useState<StorageAbstract>();

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
      (async () => {
        try {
          const offlinePage = await storage?.get('pageOffline');
          if (offlinePage) setOfflinePage(offlinePage);
        } catch (error) {
          console.error('Error getting offline page:', error);
        }
      })();
    }

    function handleOnline() {
      setIsOnline(true);
      setOfflinePage(undefined);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [storage]);

  const contextValue: IOfflineContext = {
    isOnline,
    offlinePage,
  };

  return (
    <OfflineContext.Provider value={contextValue}>
      {children}
    </OfflineContext.Provider>
  );
};

export { OfflineProvider };

export const useOfflinePageContext = () => {
  const result = useContext(OfflineContext);

  if (!result) {
    console.error(
      'Context used outside of its Provider or writeKey not provided!'
    );
  }

  return {
    isOnline: result?.isOnline,
    offlinePage: result?.offlinePage,
  };
};
