import {
  BeesAlert,
  BeesAlign,
  BeesAnchor,
  BeesAppBarWeb,
  BeesAutoProgressTimeBar,
  BeesAvatar,
  BeesAzMap,
  BeesAzureAddressAutoComplete,
  BeesBadge,
  BeesBadgeCounter,
  BeesBannerCookie,
  BeesBarcode,
  BeesBottomBar,
  BeesBottomBarFlex,
  BeesBottomBarItem,
  BeesBrand,
  BeesBrandSelect,
  BeesButton,
  BeesButtonDownload,
  BeesCalendar,
  BeesCenter,
  BeesChip,
  BeesCircularProgressBar,
  BeesColumn,
  BeesContainer,
  BeesCustomIconButton,
  BeesDataVisualization,
  BeesDatePicker,
  BeesDatePickerV2,
  BeesDimmer,
  BeesDivPartialTimerUpdate,
  BeesDivider,
  BeesDragAndDropContainer,
  BeesDragAndDropItem,
  BeesDropdown,
  BeesExpandableSection,
  BeesExpanded,
  BeesExtrapolatedContainer,
  BeesFlatButton,
  BeesFlex,
  BeesFlipperAction,
  BeesFooter,
  BeesFooterBar,
  BeesForm,
  BeesGoogleAddressAutoComplete,
  BeesGridTile,
  BeesGridView,
  BeesHeaderModal,
  BeesIcon,
  BeesImageView,
  BeesIndicator,
  BeesInkWell,
  BeesInputText,
  BeesInputTextV2,
  BeesLinearProgress,
  BeesLinkButton,
  BeesListItem,
  BeesListTile,
  BeesListView,
  BeesLoad,
  BeesMaskButton,
  BeesMediaQuery,
  BeesModal,
  BeesMultiselectDropdown,
  BeesOptionSelect,
  BeesOutlineButton,
  BeesPadding,
  BeesPage,
  BeesPrice,
  BeesProductProvider,
  BeesProgressMeter,
  BeesProgressStatusBar,
  BeesProgressTracker,
  BeesPullingData,
  BeesQrcode,
  BeesQuantityAddOld,
  BeesRadioButtom,
  BeesRadioGroup,
  BeesRangeSlider,
  BeesRatingStar,
  BeesRow,
  BeesSearch,
  BeesSearchSelect,
  BeesSegmentedControl,
  BeesSelectDropdown,
  BeesSelectField,
  BeesSelectFieldItem,
  BeesSelectPoc,
  BeesSelectPocItem,
  BeesSellerPartialTimerUpdate,
  BeesSideScroll,
  BeesSidebar,
  BeesSimpleTablePagination,
  BeesSizedBox,
  BeesSkeleton,
  BeesSlider,
  BeesSliverList,
  BeesStack,
  BeesTab,
  BeesTableItem,
  BeesTablePagination,
  BeesTableRow,
  BeesTabs,
  BeesTag,
  BeesText,
  BeesTextLink,
  BeesTextView,
  BeesTextarea,
  BeesTitle,
  BeesToast,
  BeesToggle,
  BeesTooltip,
  BeesWebview,
  BeesWrap,
} from '@bees-lite-web/honeycomb-web-react';
import {
  BeesAppBar$,
  BeesAppBarAction$,
  BeesAzureMaps$,
  BeesBottomSheet$,
  BeesCard$,
  BeesCardHeader$,
  BeesCheckbox$,
  BeesCollapse$,
  BeesCustomScrollView$,
  BeesExpansionTile$,
  BeesFlexibleSpaceBar$,
  BeesMaps$,
  BeesProductFlipper$,
  BeesQuantityOld$,
  BeesSelectList$,
  BeesSliverAppBar$,
  BeesSwitch$,
  BeesTable$,
} from './components';

export const mapper: { [key: string]: unknown } = {
  simplePagination: BeesSimpleTablePagination,
  divPartialTimerUpdate: BeesDivPartialTimerUpdate,
  azureMaps: BeesAzureMaps$,
  azmaps: BeesAzMap,
  switch: BeesSwitch$,
  expansionTile: BeesExpansionTile$,
  flipperAction: BeesFlipperAction,
  flipper: BeesProductFlipper$,
  card: BeesCard$,
  title: BeesTitle,
  flatButton: BeesFlatButton,
  outlineButton: BeesOutlineButton,
  linkButton: BeesLinkButton,
  page: BeesPage,
  container: BeesContainer,
  sizedBox: BeesSizedBox,
  column: BeesColumn,
  row: BeesRow,
  form: BeesForm,
  expanded: BeesExpanded,
  center: BeesCenter,
  imageView: BeesImageView,
  skeleton: BeesSkeleton,
  listView: BeesListView,
  listTile: BeesListTile,
  padding: BeesPadding,
  selectField: BeesSelectField,
  selectFieldItem: BeesSelectFieldItem,
  divider: BeesDivider,
  mediaQuery: BeesMediaQuery,
  textView: BeesTextView,
  gridView: BeesGridView,
  gridTile: BeesGridTile,
  price: BeesPrice,
  quantity: BeesQuantityOld$,
  quantityAdd: BeesQuantityAddOld,
  productProvider: BeesProductProvider,
  modal: BeesModal,
  listItem: BeesListItem,
  selectPoc: BeesSelectPoc,
  selectPocItem: BeesSelectPocItem,
  bottomBar: BeesBottomBar,
  bottomBarItem: BeesBottomBarItem,
  bottomSheet: BeesBottomSheet$,
  appBar: BeesAppBar$,
  appBarAction: BeesAppBarAction$,
  iconWidget: BeesIcon,
  inkWell: BeesInkWell,
  bottomBarFlex: BeesBottomBarFlex,
  linearProgress: BeesLinearProgress,
  table: BeesTable$,
  tableItem: BeesTableItem,
  tableRow: BeesTableRow,
  checkbox: BeesCheckbox$,
  header: BeesCardHeader$,
  indicator: BeesIndicator,
  footerBar: BeesFooterBar,
  extrapolatedContainer: BeesExtrapolatedContainer,
  anchor: BeesAnchor,
  stack: BeesStack,
  tag: BeesTag,
  customScrollView: BeesCustomScrollView$,
  sliverList: BeesSliverList,
  sliverAppBar: BeesSliverAppBar$,
  flexibleSpaceBar: BeesFlexibleSpaceBar$,
  inputText: BeesInputText,
  align: BeesAlign,
  rating: BeesRatingStar,
  wrap: BeesWrap,
  maskButton: BeesMaskButton,
  barcode: BeesBarcode,
  alert: BeesAlert,
  brandSelect: BeesBrandSelect,
  collapse: BeesCollapse$,
  tooltip: BeesTooltip,
  circularProgressBar: BeesCircularProgressBar,
  sidebar: BeesSidebar,
  pagination: BeesTablePagination,
  selectSearch: BeesSearchSelect,
  bessTabs: BeesTabs,
  bessTab: BeesTab,
  beesToast: BeesToast,
  datePicker: BeesDatePicker,
  customIconButton: BeesCustomIconButton,
  webView: BeesWebview,
  radioGroup: BeesRadioGroup,
  radioButton: BeesRadioButtom,
  selectDropdown: BeesSelectDropdown,
  dropdown: BeesDropdown,
  appBarWeb: BeesAppBarWeb,
  beesHeaderModal: BeesHeaderModal,
  addressAzureAutoComplete: BeesAzureAddressAutoComplete,
  addressGoogleAutoComplete: BeesGoogleAddressAutoComplete,
  bannerCookie: BeesBannerCookie,
  buttonDowload: BeesButtonDownload,
  button: BeesButton,
  flex: BeesFlex,
  badge: BeesBadge,
  beesSideScroll: BeesSideScroll,
  badgeCounter: BeesBadgeCounter,
  avatar: BeesAvatar,
  chip: BeesChip,
  brand: BeesBrand,
  dimmer: BeesDimmer,
  multiselectDropdown: BeesMultiselectDropdown,
  progressStatusBar: BeesProgressStatusBar,
  beesText: BeesText,
  expandableSection: BeesExpandableSection,
  progressMeter: BeesProgressMeter,
  toggle: BeesToggle,
  loader: BeesLoad,
  optionSelect: BeesOptionSelect,
  textArea: BeesTextarea,
  progressTracker: BeesProgressTracker,
  slider: BeesSlider,
  dataVisualization: BeesDataVisualization,
  beesSearch: BeesSearch,
  beesRangeSlider: BeesRangeSlider,
  inputTextV2: BeesInputTextV2,
  calendar: BeesCalendar,
  textLink: BeesTextLink,
  datePickerV2: BeesDatePickerV2,
  beesSelectList: BeesSelectList$,
  sellerPartialTimerUpdate: BeesSellerPartialTimerUpdate,
  segmentedControl: BeesSegmentedControl,
  footer: BeesFooter,
  pullingData: BeesPullingData,
  beesMaps: BeesMaps$,
  dragAndDropContainer: BeesDragAndDropContainer,
  dragAndDropItem: BeesDragAndDropItem,
  autoProgressTimeBar: BeesAutoProgressTimeBar,
  qrCode: BeesQrcode,
};
