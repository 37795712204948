import { concat, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Nullable } from '../interfaces/maybe.type';
import { StorageAbstract } from './storage.abstract';

export class StorageLocalStorage extends StorageAbstract {
  private _subject = new Subject<{
    key: Nullable<string>;
    value: Nullable<any>;
  }>();

  async get(key: string): Promise<any> {
    const value = localStorage.getItem(key);

    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        console.error(e);
        return null;
      }
    }

    return null;
  }

  async set(key: string, value: any): Promise<void> {
    localStorage.setItem(key, JSON.stringify(value));
    this._subject.next({ key, value });
  }

  watch(key: string): Observable<any> {
      return concat(
      this.get(key),
      this._subject.asObservable().pipe(
        filter((e) => e.key === key || e.key === null),
        map((e) => e.value)
      )
    );
  }

  async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
    this._subject.next({ key, value: null });
  }

  async clear(): Promise<void> {
    localStorage.clear();
    this._subject.next({ key: null, value: null });
  }
}
