import { BaseContext } from '@bees-lite-web/core';

export const setKeepedState = (context: BaseContext) => {
  let keepedStates: BaseContext[] = JSON.parse(
    (localStorage.getItem('keepedStates') as any) || '[]'
  );
  const saved = keepedStates?.find((e) => e.id === context.id);

  if (context.keepState && !saved) {
    keepedStates.push(context);
    localStorage.setItem('keepedStates', JSON.stringify(keepedStates));
  } else if (context.keepState && saved) {
    keepedStates[
      keepedStates.indexOf(
        (keepedStates as any).find((c: BaseContext) => c.id === context.id)
      )
    ] = context;

    localStorage.setItem('keepedStates', JSON.stringify(keepedStates));
  } else if (!context.keepState && saved) {
    keepedStates = keepedStates.filter((e) => e.id !== context.id);
    localStorage.setItem('keepedStates', JSON.stringify(keepedStates));
  }
};
