import AppState from '../State';

export const onDeleteContextData = (state: AppState, id: string) => {
  const newState: AppState = new AppState(state.data);

  if (newState?.data?.[id]) {
    delete newState?.data?.[id];
    return newState;
  } else return state;
};
