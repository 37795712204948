import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sendAnalytics } from '../../../utils/sendAnalytics';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { IAMAbstractService } from '../../services/IAM/IAM.abstract.service';

export interface IOpenOauthJourneyParams {
  journey: string;
  prompt_values?: string;
  skeleton?: string;
  ui_locales?: string;
  analyticsData?: string;
}

export class ActionsOpenOauthJourneyHandler
  implements IEventHandler<IOpenOauthJourneyParams>
{
  constructor(
    private IAMService: IAMAbstractService,
    private analytics: AnalyticsBrowser | undefined
  ) {}

  handler(
    source: Observable<Action<'action', IOpenOauthJourneyParams>>
  ): Observable<Action<'action', IOpenOauthJourneyParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.OPEN_OAUTH_JOURNEY),
      tap(async (e) => {
        if (e.parameters && this.analytics) {
          await sendAnalytics(e.parameters, this.analytics);
        }
        this.IAMService.login();
      })
    );
  }
}
