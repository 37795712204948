import { getCookie, setCookie } from '../../../../../../utils/cookies/cookies';
import {
  DEBUG_KEEPER_HEADERS_COOKIE,
  DEBUG_KEEPER_URL_COOKIE,
} from '../constants';

interface IKeeperDebugForm {
  url?: string;
  headerKey?: string;
  headerValue?: string;
}

export const KeeperDebugHandler = (debugForm: IKeeperDebugForm) => {
  const { url, headerKey, headerValue } = debugForm;

  if (url) {
    setCookie(DEBUG_KEEPER_URL_COOKIE, url);
  }

  if (headerKey && headerValue) {
    const actualDebugHeaders = getCookie(DEBUG_KEEPER_HEADERS_COOKIE);

    if (actualDebugHeaders) {
      const finalHeaders = JSON.parse(actualDebugHeaders);
      finalHeaders[headerKey] = headerValue;
      setCookie(DEBUG_KEEPER_HEADERS_COOKIE, JSON.stringify(finalHeaders));
    } else {
      setCookie(
        DEBUG_KEEPER_HEADERS_COOKIE,
        JSON.stringify({ [headerKey]: headerValue })
      );
    }
  }

  window.location.reload();
};

export const getDebugUrl = () => {
  const debugUrl = getCookie(DEBUG_KEEPER_URL_COOKIE);

  return debugUrl || undefined;
};

export const getDebugHeaders = () => {
  const debugHeaders = getCookie(DEBUG_KEEPER_HEADERS_COOKIE);

  if (debugHeaders) {
    return JSON.parse(debugHeaders);
  } else {
    return;
  }
};

export const deleteDebugHeader = (headerName: string) => {
  const actualDebugHeaders = getCookie(DEBUG_KEEPER_HEADERS_COOKIE);

  if (actualDebugHeaders) {
    const finalHeaders = JSON.parse(actualDebugHeaders);
    delete finalHeaders[headerName];

    setCookie(DEBUG_KEEPER_HEADERS_COOKIE, JSON.stringify(finalHeaders));
  }

  window.location.reload();
};
