import { StorageAbstract } from '../../core/storage/storage.abstract';

export async function logoutHandler(store?: StorageAbstract): Promise<void> {
  await clearData(store);
  window.location.reload();
}

async function clearData(store?: StorageAbstract) {
  if (store) await store.clear();
  else localStorage.clear();
  window.sessionStorage.clear();
  await clearCache();
}

async function clearCache() {
  try {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
  } catch (error) {
    console.log('Error clearing cache', error);
  }
}
