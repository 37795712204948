import { Action } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { StorageAbstract } from '../storage/storage.abstract';

export const applyProcessedRouteToUrl =
  <T extends Action>(store: StorageAbstract) =>
  (source$: Observable<T>) => {
    return source$.pipe(
      concatMap(async (e) => {
        const url = e.url;
        const [countryCode, language, routes = [], userPreference = {}] =
          await Promise.all([
            store.get('country'),
            store.get('language'),
            store.get('routes'),
            store.get('userPreference'),
          ]);

        const route = routes.find((r: any) => r.url === url);
        let finalurl = e;
        if (route) {
          finalurl = {
            ...e,
            url: route.processedUrl
              .replace('{countryCode}', countryCode)
              .replace('{language}', language),
          } as T;
        }

        return {
          ...finalurl,
          url: finalurl.url?.replace(
            /(?<REPLACE>url_user:)(?<KEY>.+?)=(?<VALUE>[^&]+)/g,
            (...rest: any) => {
              const { KEY, VALUE } = rest[rest.length - 1];
              const finalvalue = userPreference[KEY] ?? VALUE;
              return `${KEY}=${finalvalue}`;
            }
          ),
        };
      })
    );
  };
