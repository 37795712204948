export type HornetOperations =
  | 'set'
  | 'sum'
  | 'subtract'
  | 'multiply'
  | 'filter'
  | 'range'
  | 'gt'
  | 'lt'
  | 'lte'
  | 'eq'
  | 'neq'
  | 'and'
  | 'or';

export enum HornetOperationsEnum {
  'set',
  'sum',
  'subtract',
  'multiply',
  'filter',
  'range',
  'gt',
  'lt',
  'lte',
  'eq',
  'neq',
  'and',
  'or',
}
