import { Subject, timer } from 'rxjs';
import { mapTo, mergeMap, startWith } from 'rxjs/operators';

interface IDisplayMessageSubject {
  message: string;
  interval: number; // Time in seconds. Ex: 2
}

export const displayMessageSubject = new Subject<IDisplayMessageSubject | null>();

export const displayMessageSubscriber = displayMessageSubject.pipe(
  mergeMap((e) => {
    return timer(e!.interval * 1000).pipe(mapTo(null), startWith(e?.message));
  }),
);
