import React from 'react';
import { environment } from '../../../../environments/environment';
import KeeperDebug from './KeeperDebug/KeeperDebug';

const DebugDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isDebugOn = environment.debug;

  if (!isDebugOn) {
    return null;
  }

  return isOpen === true ? (
    <div
      data-testid="opened-debug-drawer"
      style={{
        zIndex: '9999',
        display: 'flex',
        position: 'fixed',
        bottom: '24px',
        right: '24px',
        padding: '24px',
        backgroundColor: 'white',
        border: '2px solid purple',
        flexDirection: 'column',
      }}
    >
      <h2
        style={{
          color: 'purple',
          fontSize: '24px',
          marginBottom: '16px',
        }}
      >
        Debug Drawer
      </h2>
      <KeeperDebug />
      <button
        data-testid="close-debug-drawer-button"
        style={{
          cursor: 'pointer',
        }}
        onClick={() => setIsOpen(false)}
      >
        Close Debug Drawer
      </button>
    </div>
  ) : (
    <div
      data-testid="closed-debug-drawer"
      style={{
        zIndex: '99',
        display: 'flex',
        position: 'fixed',
        bottom: '24px',
        right: '24px',
      }}
    >
      <button
        data-testid="open-debug-drawer-button"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen(true)}
      >
        Open Debug Drawer
      </button>
    </div>
  );
};

export default DebugDrawer;
