import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from '../../../environments/environment';
import { IAMAbstractService } from './IAM.abstract.service';

type Obj<T> = Record<string, T>;

interface RequesterOptions {
  headers?: Record<string, string>;
  body?: Record<string, any>;
}

export class IAMAzureService extends IAMAbstractService {
  private readonly authRoutes = {
    authVerify: '/auth/verify',
    login: '/login',
    logout: '/logout',
    refresh: '/refresh',
  };
  private readonly lStorageKeys = {
    auth: 'auth',
    country: 'country',
    language: 'language',
    registrationRoute: 'registrationRoute',
    userPreference: 'userPreference',
    segmentKey: 'ajs_anonymous_id',
    lastJourney: 'lastJourney',
  };
  private readonly urlTemplates = {
    login: '{{bffUrl}}/login/{{country}}/{{language}}',
    refresh: '{{bffUrl}}/refresh/{{country}}/{{language}}',
    forgotPassword: '{{bffUrl}}/forgot-password/{{country}}/{{language}}',
    signUp: '{{bffUrl}}/signup/{{country}}/{{language}}',
  };
  private readonly journeys = {
    signign: 'signin',
    signup: 'signup',
    forgotPwd: 'forgot-password',
  };

  constructor(private readonly analytics: AnalyticsBrowser | undefined) {
    super();
  }

  async login(): Promise<void> {
    const { bffUrl, language, country } = this.getCommomUrlValues();
    if (!country || !language) {
      this.error('Missing parameters country or language.');
      this.goTo(this.authRoutes.logout);
      return;
    }

    this.saveInStorage({
      [this.lStorageKeys.lastJourney]: this.journeys.signign,
    });
    const loginUrl = this.replace(this.urlTemplates.login, [
      { bffUrl, country, language },
    ]);
    const result = await this.requester(loginUrl, 'get');
    if (!result.ok) {
      this.log('Login fetch ERROR', 'error');
      this.goTo(this.authRoutes.logout);
      return;
    }

    const { action } = await result.json();
    this.saveInStorage(action.save);
    const goto = action.goto;
    goto && this.goTo(goto);
  }

  async createAccount() {
    const { bffUrl, language, country } = this.getCommomUrlValues();
    if (!country || !language) {
      this.error('Missing parameters country or language.');
      this.goTo(this.authRoutes.logout);
      return;
    }

    this.saveInStorage({
      [this.lStorageKeys.lastJourney]: this.journeys.signup,
    });
    const loginUrl = this.replace(this.urlTemplates.signUp, [
      { bffUrl, country, language },
    ]);
    const result = await this.requester(loginUrl, 'get');

    if (!result.ok) {
      this.log('Login fetch ERROR', 'error');
      this.goTo(this.authRoutes.logout);
      return;
    }

    const { action } = await result.json();
    this.saveInStorage(action.save);
    const goto = action.goto;
    goto && this.goTo(goto);
  }

  async openForgotPasswordJourney() {
    const { bffUrl, language, country } = this.getCommomUrlValues();
    if (!country || !language) {
      this.error('Missing parameters country or language.');
      this.goTo(this.authRoutes.logout);
      return;
    }

    this.saveInStorage({
      [this.lStorageKeys.lastJourney]: this.journeys.forgotPwd,
    });
    const forgotPwdUrl = this.replace(this.urlTemplates.forgotPassword, [
      { bffUrl, country, language },
    ]);
    const result = await this.requester(forgotPwdUrl, 'get');

    if (!result.ok) {
      this.log('Login fetch ERROR', 'error');
      this.goTo(this.authRoutes.logout);
      return;
    }

    const { action } = await result.json();
    this.saveInStorage(action.save);
    const goto = action.goto;
    goto && this.goTo(goto);
  }

  async getAccessToken(state: string, code: string): Promise<void> {
    const { bffUrl, language, country } = this.getCommomUrlValues();

    if (!country || !language) {
      this.error('Missing parameters country or language.');
      this.goTo(this.authRoutes.logout);
      return;
    }

    const tokenUrl = this.replace(this.urlTemplates.login, [
      { bffUrl, country, language },
    ]);
    if (this.analytics) {
      (await this.analytics.user()).anonymousId();
    } else {
      console.error('IAMAzureService Analytics not found.');
    }

    const result = await this.requester(tokenUrl, 'post', {
      headers: { 'Content-Type': 'application/json' },
      body: { state, code },
    });

    if (!result.ok) {
      this.error('GetAccessToken error: ', result);
      this.goTo(this.authRoutes.logout);
      return;
    }

    try {
      const { action } = await result.json();
      if (action.error) {
        this.error(action.error);
        action.goto
          ? this.goTo(action.goto)
          : this.goTo(this.authRoutes.logout);
        return;
      }

      this.saveInStorage(action.save);
      this.goTo(action.goto);
    } catch (e: any) {
      this.error('GetAccessToken error: ', e);
      this.goTo(this.authRoutes.logout);
    }
  }

  async validateToken(): Promise<void> {
    if (
      this.matchUrl(
        [
          'core_config',
          this.authRoutes.login,
          this.authRoutes.logout,
          this.authRoutes.authVerify,
        ],
        '/'
      )
    ) {
      return;
    }

    const auth = this.loadFromLStorage(this.lStorageKeys.auth);

    if (!auth || !Object.keys(auth).length) {
      this.error('Missing auth object.');
      this.goTo(this.authRoutes.logout);
      return;
    }
  }

  private goTo(path: string) {
    window.location.href = path;
  }

  private loadFromLStorage(key: string): string | Obj<string> {
    const defaultValue = '""';
    try {
      return JSON.parse(localStorage.getItem(key) || defaultValue);
    } catch (error) {
      console.error('Error parsing JSON from localStorage', error);
      return defaultValue;
    }
  }

  private removeFromLStorage(key: string): void {
    localStorage.removeItem(key);
  }

  private replace(template: string, values: Record<string, any>[]) {
    values.forEach((v) => {
      Object.keys(v).forEach((k) => {
        template = template.replace(`{{${k}}}`, v[k]);
      });
    });
    return template;
  }

  private matchUrl(urls: string[], pathName?: string): boolean {
    if (window.location.pathname === pathName) return true;

    const url = window.location.href;
    return urls.some((u) => url.includes(u));
  }

  private error(...message: any[]) {
    console.error('[IAM Service][ERROR]:', ...message);
  }

  private log(message: any, kind?: 'log' | 'error' | 'warn') {
    const msg = Array.isArray(message) ? [...message] : message;
    const title = `[IAM Service][${kind?.toUpperCase()}]: `;
    console.log(title, msg);
  }

  private getHeaders() {
    const userPreference = this.loadFromLStorage(
      this.lStorageKeys.userPreference
    );
    return {
      'x-last-journey': this.loadFromLStorage(this.lStorageKeys.lastJourney),
      'x-segment-id': this.loadFromLStorage(this.lStorageKeys.segmentKey),
      'x-user-preferences': JSON.stringify(userPreference) || '',
    };
  }

  private saveInStorage(save: Record<string, any>) {
    if (!save) return;
    Object.keys(save).forEach((el) => {
      const storageElement = this.loadFromLStorage(el);
      if (typeof save[el] === 'object' && storageElement) {
        localStorage.setItem(
          el,
          JSON.stringify({
            ...(storageElement as Obj<string>),
            ...save[el],
          })
        );
        return;
      }
      localStorage.setItem(el, JSON.stringify(save[el]));
    });
  }

  private async requester(
    url: string,
    method: string,
    options?: RequesterOptions
  ) {
    const headers = options?.headers;
    const body = options?.body;

    return fetch(url, {
      headers: {
        ...headers,
        ...this.getHeaders(),
      } as any,
      body: JSON.stringify(body),
      method: method.toUpperCase(),
    }).catch((error) => {
      this.log(error);
      return {
        ok: false,
        status: 500,
        statusText: 'Internal Server Error',
        json: async () => ({ error: 'Erro na requisição' }),
        text: async () => 'Erro na requisição',
      };
    });
  }

  private getCommomUrlValues() {
    const bffUrl = environment.keeperUrl;
    const country = this.loadFromLStorage(this.lStorageKeys.country);
    const language = this.loadFromLStorage(this.lStorageKeys.language);
    return { bffUrl, country, language };
  }
}
