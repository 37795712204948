import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { concatMap } from 'rxjs/operators';
import { logoutHandler } from '../logout/logout-handler';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export interface IErrorParams extends AjaxError {
  response: KeeperPayload;
}

export class Error403Handler implements IEventHandler<IErrorParams> {
  constructor(private store: StorageAbstract) {}

  handler(
    source: Observable<Action<'error', IErrorParams>>
  ): Observable<Action<'error', IErrorParams>> {
    return source.pipe(
      ofType(
        (e) =>
          e.parameters?.status === 403 ||
          e.parameters?.response?.responseCode === 403
      ),
      concatMap(async (e) => {
        await logoutHandler(this.store);
        return e;
      })
    );
  }
}
