import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { MutableRefObject } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {
  Connection,
  ConnectionTypeEnum,
} from '../../services/keeper/keeper.payload.interface';
import socketConnection from './connections/socket/connection.socket';

export class ConnectionHandler implements IEventHandler<any> {
  constructor(private connections: MutableRefObject<BehaviorSubject<any>>) {}

  handler(source: Observable<Action<'response', any>>): any {
    return source.pipe(
      ofType((e) => !!e?.parameters?.action?.connections?.length),
      concatMap(async (e) => {
        const connections: Connection[] =
          e?.parameters?.action?.connections || [];

        for (const connection of connections) {
          switch (connection.connectionType) {
            case ConnectionTypeEnum.SOCKET:
              socketConnection({ connections: this.connections, connection });
              break;
          }
        }
      })
    );
  }
}
