export const deletePropertyByPath = (
  object: { [key: string]: any },
  path: string
) => {
  if (!object || !path) {
    return;
  }
  let paths: any = '';

  if (typeof path === 'string') {
    paths = path.split('.');
  }

  for (let i = 0; i < paths.length - 1; i++) {
    object = object[paths[i]];

    if (typeof object === 'undefined') {
      return;
    }
  }

  delete object[paths.pop()];
};
