import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

export class DeleteHandler implements IEventHandler {
  constructor(private bffService: KeeperAbstractService) {}

  handler(
    source: Observable<Action<'delete', any>>
  ): Observable<Action<'delete'>> {
    return source.pipe(
      concatMap(async (e) => {
        await this.bffService.delete(e.url!);
        return e;
      })
    );
  }
}
