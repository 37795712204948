import { BaseContext, Reaction } from '@bees-lite-web/core';
import AppState from '../State';
import { getValueByPath } from '../utils/getValueByPath';
import { setValueToPath } from '../utils/setValueToPath';

type Equality = 'equal' | 'different';
interface IComplement {
  equality: Equality;
  path: string;
  comparisonValue: any;
  list: any[];
}

// String model "different:<path>:<diff-value>" or "equal:<path>:<equal-value>""
export const filterArray = (value: Array<any>, complement: IComplement) => {
  const equality: Equality = complement?.equality;
  const path = complement?.path;
  const comparisonValue = complement?.comparisonValue;
  const list = complement?.list;

  if (!equality || !path || !comparisonValue || !list) return value;

  return list.filter((element) =>
    equality === 'equal'
      ? getValueByPath(element, path)?.toString() === comparisonValue
      : getValueByPath(element, path)?.toString() !== comparisonValue
  );
};

export const onUpdateContextDataFilterOperation = (
  state: AppState,
  reaction: Reaction
) => {
  const newData: {
    [key: string]: BaseContext;
  } = { ...state.data };

  const newState: AppState = new AppState(newData);

  if (!newData[reaction.targetId]?.data) return state;

  const value = getValueByPath(newData[reaction.targetId].data, reaction.path);

  let newValue;

  const setNewValue = (method: (value: any, complement: any) => any) => {
    newValue = method(value, reaction.expression.value);
  };

  if (reaction.expression.type === 'array') {
    setNewValue(filterArray);
  }

  setValueToPath(newData[reaction.targetId].data, newValue, `${reaction.path}`);

  return newState;
};
