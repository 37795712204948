import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MELLIFERA_EVENT_TYPE, emmiter } from '../../core/eventContext';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

function getLastRouteParam(url: string, lastRouteParam: string) {
  const queryIntersection = url.includes('?') ? '&' : '?';
  return lastRouteParam ? `${queryIntersection}lastRoute=${lastRouteParam}` : '';
}

export class ResponseActionsGoToHandler
  implements IEventHandler<KeeperPayload>
{
  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType((e) => !!e.parameters?.action?.goTo),
      tap(async (e) => {
        const searchParams = new URLSearchParams(window.location.href)
        const lastRouteParam = searchParams.get('lastRoute') as string
        const url = e.parameters!.action!.goTo!

        emmiter.next(
          new Action({
            type: MELLIFERA_EVENT_TYPE.navigation,
            url: `${url}${getLastRouteParam(url, lastRouteParam)}`,
            eventTrigger: 'goToAction',
          })
        );
      })
    );
  }
}
