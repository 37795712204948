import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { applyProcessedRouteToUrl } from '../../core/pipes/apply-proccessed-route-to-url.observable.pipe';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

export class GetHandler implements IEventHandler {
  constructor(
    private bffService: KeeperAbstractService,
    private store: StorageAbstract
  ) {}

  handler(source: Observable<Action<'get', any>>): Observable<Action<'get'>> {
    return source.pipe(
      applyProcessedRouteToUrl(this.store),
      concatMap(async (e) => {
        await this.bffService.get(e.url);
        return e;
      })
    );
  }
}
