import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable, Subject } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { tap } from 'rxjs/operators';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

interface IErrorParams extends AjaxError {}

export class RequestErrorHandler implements IEventHandler<IErrorParams> {
  constructor(private dispatcher: Subject<KeeperPayload>) {}
  handler(
    source: Observable<Action<'error', IErrorParams>>
  ): Observable<Action<'error', IErrorParams>> {
    return source.pipe(
      tap((e) => {
        console.log('RequestErrorHandler', e);
        if (e.parameters) {
          console.log((e.parameters.response?.action?.message));
          console.log(e.parameters.message)
        }
        this.dispatcher.next(e.parameters && e.parameters.response);
      })
    );
  }
}
