import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export class ResponseActionsSaveRedirectHandler
  implements IEventHandler<KeeperPayload>
{
  constructor(private storage: StorageAbstract) {}

  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType((e) => !!e?.parameters?.action?.save?.redirectTo),
      concatMap(async (e) => {
        const redirectTo = e.parameters!.action!.save!.redirectTo;

        delete e.parameters!.action!.save!.redirectTo;

        const saveObject = e.parameters!.action!.save!;

        await Promise.all(
          Object.keys(saveObject).map((key) => {
            return this.storage.set(key, saveObject[key]);
          })
        );

        window.location.href = redirectTo;

        return e;
      })
    );
  }
}
