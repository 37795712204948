import { ajax } from 'rxjs/ajax';
import {
  AjaxCreationMethod,
  AjaxRequest,
} from 'rxjs/internal/observable/dom/AjaxObservable';
import { RequesterInfraAbstract } from '../services/requester.infra.abstract';
import { ResponseInfraInterface } from '../services/response.infra.interface';

export class RxjsAjaxRequesterInfra extends RequesterInfraAbstract {
  agent: AjaxCreationMethod;
  headers: Record<string, string> = {};

  constructor() {
    super();
    this.agent = ajax;
  }

  configureHeader(headers: Record<string, string>) {
    this.headers = { ...this.headers, ...headers };
  }

  private createAjax(request: AjaxRequest) {
    return ajax({
      ...request,
      headers: this.headers,
    });
  }

  get<T>(url: string, options?: any): Promise<ResponseInfraInterface<T>> {
    return this.createAjax({
      url,
      method: 'GET',
      ...options,
    }).toPromise() as Promise<ResponseInfraInterface<T>>;
  }

  post<T>(
    url: string,
    body: any,
    options?: any
  ): Promise<ResponseInfraInterface<T>> {
    return this.createAjax({
      url,
      method: 'POST',
      body,
      ...options,
    }).toPromise() as Promise<ResponseInfraInterface<T>>;
  }

  patch<T>(
    url: string,
    body: any,
    options?: any
  ): Promise<ResponseInfraInterface<T>> {
    return this.createAjax({
      url,
      method: 'PATCH',
      body,
      ...options,
    }).toPromise() as Promise<ResponseInfraInterface<T>>;
  }

  put<T>(
    url: string,
    body: any,
    options?: any
  ): Promise<ResponseInfraInterface<T>> {
    return this.createAjax({
      url,
      method: 'PUT',
      body,
      ...options,
    }).toPromise() as Promise<ResponseInfraInterface<T>>;
  }

  delete<T>(url: string, options?: any): Promise<ResponseInfraInterface<T>> {
    return this.createAjax({
      url,
      method: 'DELETE',
      ...options,
    }).toPromise() as Promise<ResponseInfraInterface<T>>;
  }
}
