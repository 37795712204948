import { ReplaySubject, Subject } from 'rxjs';
import { Action } from '../base.action';
import { Reaction } from '../base.reaction';

export class EventBus<A> {
  private _subject: ReplaySubject<Action<A> | Reaction>;

  constructor(emmiter: (_sub: Subject<Action<A> | Reaction>) => void) {
    this._subject = new ReplaySubject<Action<A> | Reaction>(undefined, 2000);
    emmiter(this._subject);
  }

  get source() {
    return this._subject.asObservable();
  }
}
