import { useEffect } from 'react';
import { environment } from '../../../environments/environment';
import {
  newRelicProd,
  newRelicSit,
  newRelicUat,
} from './scripts/newRelicScripts';

export const getNewRelicScript = () => {
  const newRelicEnv = environment.newRelic;

  switch (newRelicEnv) {
    case 'PROD':
      return newRelicProd;
    case 'SIT':
      return newRelicSit;
    case 'UAT':
      return newRelicUat;
    default:
      return null;
  }
};

export const useNewRelicScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    const newRelicScript = getNewRelicScript();

    if (newRelicScript) {
      script.innerText = newRelicScript;
      const firstMetaTag = document.head.querySelector('meta');

      if (firstMetaTag) {
        document.head.insertBefore(script, firstMetaTag.nextSibling);
      } else {
        document.head.insertBefore(script, document.head.firstChild);
      }
    }

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};
