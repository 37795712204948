import {
  HornetOperationsEnum,
  IEventHandler,
  ofTypeReaction,
  Reaction,
} from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {
  updateContextDataFilterOperation,
  updateContextDataMultiplyOperation,
  updateContextDataSetOperation,
  updateContextDataSubtractOperation,
  updateContextDataSumOperation,
} from '../../store/Events';

export class ReactionHandler implements IEventHandler<any> {
  handler(source: Observable<Reaction>): Observable<Reaction> {
    return source.pipe(
      ofTypeReaction((reaction) => {
        return !!Object.values(HornetOperationsEnum).find(
          (el) =>
            el ===
            (reaction && reaction.expression && reaction.expression.operation)
        );
      }),
      // tap(console.log),
      concatMap(async (reaction) => {
        switch (reaction.expression.operation) {
          case 'set':
            updateContextDataSetOperation(reaction);
            break;
          case 'sum':
            updateContextDataSumOperation(reaction);
            break;
          case 'subtract':
            updateContextDataSubtractOperation(reaction);
            break;
          case 'multiply':
            updateContextDataMultiplyOperation(reaction);
            break;
          case 'filter':
            updateContextDataFilterOperation(reaction);
            break;
        }
        return reaction;
      })
    );
  }
}
