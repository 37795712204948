import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import history from '../../core/browserHistory';
import { IAMAbstractService } from '../../services/IAM/IAM.abstract.service';

interface IGetAccessTokenParams {
  state: string;
  code: string;
}

export class ActionsGetAccessTokenHandler
  implements IEventHandler<IGetAccessTokenParams>
{
  constructor(private IAMService: IAMAbstractService) {}

  handler(
    source: Observable<Action<'action', IGetAccessTokenParams>>
  ): Observable<Action<'action', IGetAccessTokenParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.GET_ACCESS_TOKEN),
      concatMap(async (e) => {
        const { state, code } = e.parameters!;
        await this.IAMService.getAccessToken(state, code);

        history.replace('/');
        return e;
      })
    );
  }
}
