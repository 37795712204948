import { BaseContext, Reaction } from '@bees-lite-web/core';
import AppState from '../State';
import { getValueByPath } from '../utils/getValueByPath';
import { setKeepedState } from '../utils/setKeepedState';
import { setValueToPath } from '../utils/setValueToPath';

const multiplyNumber = (value: number, complement: number) =>
  parseFloat(`${value || 0}`) * parseFloat(`${complement || 0}`);

export const onUpdateContextDataMultiplyOperation = (
  state: AppState,
  reaction: Reaction
) => {
  const newData: {
    [key: string]: BaseContext;
  } = { ...state.data };

  const newState: AppState = new AppState(newData);

  if (!newData[reaction.targetId]?.data) return state;

  const value = getValueByPath(newData[reaction.targetId].data, reaction.path);

  let newValue;

  const setNewValue = (method: (value: any, complement: any) => any) => {
    newValue = method(value, reaction.expression.value);
  };

  if (reaction.expression.type === 'number') {
    setNewValue(multiplyNumber);
  }

  setValueToPath(newData[reaction.targetId].data, newValue, `${reaction.path}`);
  if (newState.data[reaction.targetId].keepState) {
    setKeepedState(newState.data[reaction.targetId]);
  }
  return newState;
};
