import { BaseContext } from '../../../../../../../libs/core/src';
import { addContextData } from '../../../store/Events';
import setupKeepedStates from './setupKeepedStates';
import setupStore from './setupStore';

const setup = async (renderData: any) => {
  const contextData: BaseContext[] = [];
  const promiseList: any[] = [];
  await setupStore(contextData, renderData, promiseList);

  await Promise.all(promiseList).finally(() => setupKeepedStates(contextData));

  addContextData(contextData);
};

export default setup;
