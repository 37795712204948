import { Action } from '@bees-lite-web/core';
import { useEffect } from 'react';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import history from '../../core/browserHistory';
import { emmiter, MELLIFERA_EVENT_TYPE } from '../../core/eventContext';
import { StorageAbstract } from '../../core/storage/storage.abstract';

type Props = {
  storage: StorageAbstract;
};

export const AuthVerifyScreen = (props: Props) => {
  const { location } = history;

  useEffect(() => {
    (async () => {
      const queryEntries: Record<string, string> = {};
      new URLSearchParams(location.search).forEach((value, key) => {
        queryEntries[key] = value;
      });

      emmiter.next(
        new Action({
          type: MELLIFERA_EVENT_TYPE.navigation,
          eventTrigger: 'authVerify',
          url: ActionsUrl.GET_ACCESS_TOKEN,
          parameters: {
            code: queryEntries.code,
            state: queryEntries.state,
          },
        })
      );
    })();
  }, [location.search, props]);

  return null;
};
