import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { StorageAbstract } from '../../core/storage/storage.abstract';

export interface ILogoutParams {}

export class ActionsLogoutHandler implements IEventHandler<ILogoutParams> {
  constructor(private store: StorageAbstract) {}

  handler(
    source: Observable<Action<'action', ILogoutParams>>
  ): Observable<Action<'action', ILogoutParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.LOGOUT),
      concatMap(async (e) => {
        await this.store.clear();
        window.sessionStorage.clear();
        window.location.href = '/logout';
        return e;
      })
    );
  }
}
