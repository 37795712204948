import { Action } from '@bees-lite-web/core';
import { emmiter } from '@bees-lite-web/hornet-web';
import { MutableRefObject } from 'react';
import { BehaviorSubject } from 'rxjs';
import { io } from 'socket.io-client';
import {
  Connection,
  SocketParameters,
} from '../../../../services/keeper/keeper.payload.interface';

export interface SocketPayload {
  connections: MutableRefObject<BehaviorSubject<any>>;
  connection: Connection;
}

const socketConnection = (payload: SocketPayload) => {
  const { connections, connection } = payload;

  const currentConnections = connections.current.value;

  if (!currentConnections?.[connection.connectionId]) {
    const newConnection = {
      [connection.connectionId]: io(connection.connectionString),
    };

    const events = (connection.connectionParameters as SocketParameters).events;

    for (const event of events) {
      if (event.socketEventType === 'emit') {
        newConnection[connection.connectionId].emit(
          event.eventName,
          event.data
        );
      } else if (event.socketEventType === 'listen') {
        newConnection[connection.connectionId].on(
          event.eventName,
          (data: any = event.data) => {
            if (event?.action)
              emmiter.next(
                new Action({
                  ...(event.action as any),
                  parameters: {
                    ...data,
                  },
                })
              );
          }
        );
      } else {
        console.error(
          `Socket event type parameter is invalid! value: ${event.socketEventType}`
        );
      }
    }
    connections?.current?.next({
      ...(currentConnections as any),
      ...newConnection,
    });
  } else {
    console.error(
      `A connection with connectionId ${connection.connectionId} already exists!`
    );
  }
};

export default socketConnection;
