import { BaseComponent } from '@bees-lite-web/core';
import {
  BeesAppBar,
  BeesAppBarAction,
  BeesAzureMaps,
  BeesBottomSheet,
  BeesCard,
  BeesCardHeader,
  BeesCheckbox,
  BeesCollapse,
  BeesCustomScrollView,
  BeesExpansionTile,
  BeesFlexibleSpaceBar,
  BeesGoogleMaps,
  BeesIcon,
  BeesMaps,
  BeesOptionSelect,
  BeesProductFlipper,
  BeesQuantityOld,
  BeesSelectList,
  BeesSliverAppBar,
  BeesSwitch,
  BeesTable,
  BeesTableRow,
  BeesTitle,
} from '@bees-lite-web/honeycomb-web-react';
import ReactDOMServer from 'react-dom/server';
import { ObjectParser } from '..';

// Components react to initial use cases, before create stencil components

export const FallbackComponent = ({ children }: any) => {
  return <div>{children}</div>;
};

export const BeesBottomSheet$ = ({ children, content, ...props }: any) => {
  return (
    <BeesBottomSheet {...props}>
      {children}
      <div slot="content" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={content} />
      </div>
    </BeesBottomSheet>
  );
};

export const BeesAppBar$ = ({
  children,
  title,
  action,
  bottom,
  ...props
}: any) => {
  return (
    <BeesAppBar {...props} haveBottomContent={!!bottom}>
      {children}
      <div slot="title" style={{ display: 'contents' }}>
        {typeof title === 'string' ? (
          <BeesTitle size="medium" text={title} />
        ) : (
          <ObjectParser contextData={props.contextData} json={title} />
        )}
      </div>
      <div slot="action" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={action} />
      </div>
      {!!bottom && (
        <div slot="bottom" style={{ display: 'contents' }}>
          <ObjectParser contextData={props.contextData} json={bottom} />
        </div>
      )}
    </BeesAppBar>
  );
};

export const BeesAppBarAction$ = ({ children, icon, ...props }: any) => {
  return (
    <BeesAppBarAction {...props}>
      {children}
      <div slot="icon" style={{ display: 'contents' }}>
        <BeesIcon {...icon} />
      </div>
    </BeesAppBarAction>
  );
};

export const BeesSwitch$ = ({ onChanged, ...props }: any) => {
  return <BeesSwitch changed={onChanged} {...props} />;
};

export const BeesQuantityOld$ = ({ children, onChanged, ...props }: any) => {
  return <BeesQuantityOld changed={onChanged} {...props} />;
};

export const BeesTable$ = ({ children, ...props }: any) => {
  return (
    <BeesTable {...props}>
      {children.map((row: any, index: number) => (
        <BeesTableRow key={`BeesTableRow-${index}`}>{row}</BeesTableRow>
      ))}
    </BeesTable>
  );
};

export const BeesExpansionTile$ = ({ title, children, ...props }: any) => {
  return (
    <BeesExpansionTile {...props}>
      <div slot="title" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={title} />
      </div>
      {children}
    </BeesExpansionTile>
  );
};

export const BeesCheckbox$ = ({ label, ...props }: any) => {
  return (
    <BeesCheckbox {...props}>
      <div slot="label" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={label} />
      </div>
    </BeesCheckbox>
  );
};

export const BeesAzureMaps$ = ({ ...props }: any) => {
  const getPopupComponent = (popupData: any) => {
    const partialScreen = (
      <ObjectParser
        contextData={props.contextData}
        json={popupData}
        formatPropsName={true}
      />
    );

    return ReactDOMServer.renderToString(partialScreen);
  };
  return (
    <BeesAzureMaps
      getPopupComponent={getPopupComponent}
      {...props}
    ></BeesAzureMaps>
  );
};

export const BeesGoogleMaps$ = ({ ...props }: any) => {
  const getPopupComponent = (popupData: any) => {
    const partialScreen = (
      <ObjectParser
        contextData={props.contextData}
        json={popupData}
        formatPropsName={true}
      />
    );

    return ReactDOMServer.renderToString(partialScreen);
  };
  return (
    <BeesGoogleMaps
      getPopupComponent={getPopupComponent}
      {...props}
    ></BeesGoogleMaps>
  );
};

export const BeesMaps$ = ({ ...props }: any) => {
  const getPopupComponent = (popupData: any) => {
    const partialScreen = (
      <ObjectParser
        contextData={props.contextData}
        json={popupData}
        formatPropsName={true}
      />
    );

    return ReactDOMServer.renderToString(partialScreen);
  };
  return <BeesMaps getPopupComponent={getPopupComponent} {...props}></BeesMaps>;
};

export const BeesProductFlipper$ = ({ front, back, ...props }: any) => {
  return (
    <BeesProductFlipper {...props}>
      <div slot="front" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={front} />
      </div>
      <div slot="back" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={back} />
      </div>
    </BeesProductFlipper>
  );
};

export const BeesCard$ = ({ children, header, ...props }: any) => {
  return (
    <BeesCard {...props}>
      <ObjectParser contextData={props.contextData} json={header} />
      {children}
    </BeesCard>
  );
};

export const BeesCollapse$ = ({
  children,
  displayed,
  hidden,
  ...props
}: any) => {
  return (
    <BeesCollapse {...props}>
      <div style={{ display: 'contents' }} slot="displayed">
        <ObjectParser contextData={props.contextData} json={displayed} />
      </div>
      <div style={{ display: 'contents' }} slot="hidden">
        <ObjectParser contextData={props.contextData} json={hidden} />
      </div>
      {children}
    </BeesCollapse>
  );
};

export const BeesCardHeader$ = ({
  children,
  indicator,
  title,
  info,
  ...props
}: any) => {
  return (
    <BeesCardHeader {...props}>
      <ObjectParser contextData={props.contextData} json={indicator} />
      <ObjectParser contextData={props.contextData} json={title} />
      <ObjectParser contextData={props.contextData} json={info} />
      {children}
    </BeesCardHeader>
  );
};

export const BeesCustomScrollView$ = ({ slivers, ...props }: any) => {
  return (
    <BeesCustomScrollView {...props} style={{ display: 'contents' }}>
      {slivers.map((sliver: BaseComponent) => (
        <ObjectParser
          contextData={props.contextData}
          key={sliver.key}
          json={sliver}
        />
      ))}
    </BeesCustomScrollView>
  );
};

export const BeesSliverAppBar$ = ({ title, flexibleSpace, ...props }: any) => {
  return (
    <BeesSliverAppBar {...props}>
      <div slot="title" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={title} />
      </div>
      <div slot="flexibleSpace" style={{ display: 'contents' }}>
        <ObjectParser contextData={props.contextData} json={flexibleSpace} />
      </div>
    </BeesSliverAppBar>
  );
};

export const BeesFlexibleSpaceBar$ = ({ background, ...props }: any) => {
  const backgroundComponent = JSON.parse(JSON.stringify(background));
  backgroundComponent.parameters.padding = '0';
  backgroundComponent.parameters.height = '0';

  return (
    <BeesFlexibleSpaceBar {...props}>
      <div slot="background" style={{ display: 'contents' }}>
        <ObjectParser
          contextData={props.contextData}
          json={backgroundComponent}
        />
      </div>
    </BeesFlexibleSpaceBar>
  );
};

export const BeesSelectList$ = ({ options, ...props }: any) => {
  return (
    <BeesSelectList {...{ ...props, options }}>
      {options.map((option: any, index: string) => {
        const key: any = `bees-option-select-${index}`;
        return (
          <BeesOptionSelect
            key={key}
            {...{
              ...option,
              children: undefined,
              childrenList: option.children,
              width: props.styles.width,
              height: props.styles.height,
              padding: props.styles.padding,
              margin: props.styles.margin,
              disabledStyle: props.styles.disabledStyle,
              focusedStyle: props.styles.focusedStyle,
              optionStyle: props.styles.optionStyle,
            }}
          >
            {option.children.map((child: any, idx: string) => {
              const key: any = `bees-option-select-${index}-child-${idx}`;
              return (
                <ObjectParser
                  key={key}
                  contextData={props.contextData}
                  json={child}
                />
              );
            })}
          </BeesOptionSelect>
        );
      })}
    </BeesSelectList>
  );
};
