import { AnalyticsBrowser } from '@segment/analytics-next';

export interface ILaunchURLParams {
  url?: string;
  journey?: string;
  prompt_values?: string;
  skeleton?: string;
  ui_locales?: string;
  analyticsData?: string;
}

export async function sendAnalytics(
  params: ILaunchURLParams,
  analytics: AnalyticsBrowser
): Promise<void> {
  if (params.analyticsData) {
    const analyticsData = JSON.parse(params.analyticsData);
    const eventName = analyticsData.name;

    delete analyticsData.name;

    try {
      await analytics.track(eventName, analyticsData);
    } catch (e) {
      console.error(e);
    }
  }
}
