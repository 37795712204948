export enum MessageType {
  INIT = 'INIT',
  REQ_RESPONSE = 'REQ_RESPONSE',
  REQ_ERROR = 'REQ_ERROR',
  REQ_AUTHORIZATION_ERROR = 'REQ_AUTHORIZATION_ERROR',
  FETCH = 'FETCH',
  UNKNOWN_MESSAGE = 'UNKNOWN_MESSAGE',
  SKIP = 'SKIP',
}

export interface MelliferaPersistentWorkerMessage {
  type: MessageType;
  parameters?: any;
}

export interface MelliferaPersistentNotificationServiceSettings {
  recipient: string;
  pollInterval: number;
  url: string;
  soundUrl: string;
  ignoredUrls: string[];
}
