import { filter } from 'rxjs/operators';
import { Reaction } from '../base.reaction';

export function ofTypeReaction<
  T extends string | ((arg: S) => boolean),
  S extends Reaction
>(...allowedTypes: Array<T>) {
  return filter((reaction: S) => {
    return allowedTypes.every((typeOrReactionCreator: any) => {
      if (typeOrReactionCreator instanceof Function) {
        return typeOrReactionCreator(reaction as S);
      }

      return typeOrReactionCreator === reaction.expression.operation;
    });
  });
}
