import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

export interface IResizeParams {
  width: number;
  height: number;
}

export class ResizeHandler implements IEventHandler<IResizeParams> {
  private resize$ = new Subject<IResizeParams>();
  private stopSignal$ = new Subject();

  private currentFormat = '';

  constructor(
    private bffService: KeeperAbstractService,
    private store: StorageAbstract
  ) {
    this.initialize();
  }

  handler(
    source: Observable<Action<'resize', IResizeParams>>
  ): Observable<Action<'resize', IResizeParams>> {
    return source.pipe(
      tap((e) => this.resize$.next(e.parameters)),
      finalize(() => {
        this.stopSignal$.next(true);
        this.stopSignal$.complete();
      })
    );
  }

  private async initialize() {
    this.currentFormat = this.getFormat(document.body.offsetWidth);
    await this.store.set('x-os', this.currentFormat);
    this.bffService.setHeaders({
      'x-os': this.getOSHeader(this.currentFormat),
    });
  }

  private getFormat(width: number): string {
    return width >= 800 ? 'desktop' : 'desktop';
  }

  private getOSHeader(format: string) {
    if (format === 'mobile') {
      if (/(iPhone|iPad|iPod)/i.test(window.navigator.userAgent)) {
        return 'ios';
      } else {
        return 'android';
      }
    } else {
      return 'web';
    }
  }
}
