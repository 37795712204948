import { Action } from '@bees-lite-web/core';
import { useEffect } from 'react';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { emmiter, MELLIFERA_EVENT_TYPE } from '../../core/eventContext';

export const ForgotPasswordScreen = () => {
  useEffect(() => {
    (async () => {
      emmiter.next(
        new Action({
          type: MELLIFERA_EVENT_TYPE.action,
          eventTrigger: 'authVerify',
          url: ActionsUrl.OPEN_FORGOT_PASSWORD_JOURNEY,
        })
      );
    })();
  }, []);

  return null;
};
