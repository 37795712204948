import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { NotificationAudio } from '../../core/notificationAudio.shortcut';

export interface INotificationAudioParams {
  type?: string;
}

export class ActionsNotificationAudioHandler
  implements IEventHandler<INotificationAudioParams>
{
  handler(
    source: Observable<Action<'action', INotificationAudioParams>>
  ): Observable<Action<'action', INotificationAudioParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.NOTIFICATION_AUDIO),
      tap(async (action) => {
        const notificationType = action?.parameters?.type || 'NEW_ORDER';

        try {
          const audio = new Audio(
            NotificationAudio[
              notificationType as keyof typeof NotificationAudio
            ]
          );
          audio.play();
        } catch (error) {
          console.error('catch', error);
        }

      })
    );
  }
}

