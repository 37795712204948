import { BaseContext } from '@bees-lite-web/core';
import AppState from '../State';

export const onUpdateContextData = (state: AppState, context: BaseContext) => {
  const newState: AppState = new AppState({
    ...state.data,
    [context.id as any]: new BaseContext(
      context.id,
      {
        ...state.data[context.id].data,
        ...context.data,
      },
      typeof context?.keepState === 'boolean'
        ? context.keepState
        : state.data[context.id].keepState
    ),
  });

  localStorage.setItem('context', JSON.stringify(newState));
  
  return newState;
};
