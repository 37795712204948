import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { emmiter, MELLIFERA_EVENT_TYPE } from '../../core/eventContext';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

interface IChangeLanguageParams {
  language: string;
}

export class ActionsChangeLanguageHandler
  implements IEventHandler<IChangeLanguageParams>
{
  constructor(
    private bffService: KeeperAbstractService,
    private store: StorageAbstract
  ) {}

  handler(
    source: Observable<Action<'action', IChangeLanguageParams>>
  ): Observable<Action<'action', IChangeLanguageParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.CHANGE_LANGUAGE),
      concatMap(async (e) => {
        const { language } = e.parameters!;
        await this.store.set('language', language);

        const currentRoute = await this.store.get('currentRoute');
        emmiter.next(
          new Action({
            type: MELLIFERA_EVENT_TYPE.navigation,
            eventTrigger: 'changeLanguage',
            url: currentRoute,
          })
        );

        return e;
      })
    );
  }
}
