import { AppTour } from '../../../services/keeper/keeper.payload.interface';
import appTour from './appTour';

const startAppTour = async (appParam: AppTour) => {
  if (!appParam) return;

  const state = JSON.parse(localStorage.getItem('appTour') || '{}');

  if (appParam?.key === state.key && appParam?.version === state.version)
    return;

  if (
    !appParam?.config ||
    !appParam?.popoverStyles ||
    !appParam?.steps?.length ||
    !appParam?.key ||
    !appParam?.version
  ) {
    console.error('App Tour Params are invalid!');
    return;
  }

  const _appTour = await appTour({ ...(appParam as AppTour) });

  _appTour?.drive();
};
export default startAppTour;
