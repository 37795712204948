import { BaseContext, Reaction } from '@bees-lite-web/core';
import AppState from '../State';
import { getValueByPath } from '../utils/getValueByPath';
import { setKeepedState } from '../utils/setKeepedState';
import { setValueToPath } from '../utils/setValueToPath';

const sumString = (value: string, complement: string) =>
  `${value}${complement}`;

export const sumNumber = (value: number, complement: number) =>
  parseFloat(`${value || 0}`) + parseFloat(`${complement || 0}`);

export const sumObject = (
  value: { [key: string]: any },
  complement: { [key: string]: any }
) => ({ ...value, ...complement });

export const sumArray = (value: Array<any>, complement: Array<any>) =>
  value.concat(complement);

export const onUpdateContextDataSumOperation = (
  state: AppState,
  reaction: Reaction
) => {
  const newData: {
    [key: string]: BaseContext;
  } = { ...state.data };

  if (!newData[reaction.targetId]?.data) return state;

  const newState: AppState = new AppState(newData);
  const value = getValueByPath(newData[reaction.targetId].data, reaction.path);

  let newValue;

  const setNewValue = (method: (value: any, complement: any) => any) => {
    newValue = method(value, reaction.expression.value);
  };

  switch (reaction.expression.type) {
    case 'string':
      setNewValue(sumString);
      break;
    case 'number':
      setNewValue(sumNumber);
      break;
    case 'object':
      setNewValue(sumObject);
      break;
    case 'array':
      setNewValue(sumArray);
      break;
  }

  setValueToPath(newData[reaction.targetId].data, newValue, `${reaction.path}`);
  if (newState.data[reaction.targetId].keepState) {
    setKeepedState(newState.data[reaction.targetId]);
  }
  return newState;
};
