import { merge, Observable } from 'rxjs';
import { Action } from '../base.action';
import { Reaction } from '../base.reaction';
import { HornetOperationsEnum } from '../types/hornetOperations';
import { createSelector } from './createSelector';
import { IEventHandler } from './eventHandler.interface';
import { ofType } from './oftype.observable.operator';
import { ofTypeReaction } from './oftypeReaction.observable.operator';

const SPECIAL_KEY = 'action';
const REACTION_KEY = 'reaction';
const SPECIAL_TEST_ATTRIBUTE = 'url';

const testActionType = (key: string) => (e: Action) => e.type === key;

const testSpecialCase = (e: Action) =>
  !!e[SPECIAL_TEST_ATTRIBUTE]?.startsWith(SPECIAL_KEY);

const testReactionCase = (e: Reaction) =>
  !!Object.values(HornetOperationsEnum).find(
    (el) => el === e?.expression?.operation
  );

const not = (cb: (e: any) => boolean) => (value: any) => !cb(value);

export const contextHandler = <T extends string | number>(
  source: Observable<Action<T> | Reaction>,
  mapper: Partial<Record<T, IEventHandler<any>[]>>
): Observable<Action | Reaction> => {
  return merge(
    ...Object.keys(mapper)
      .map((key) => {
        if (key === SPECIAL_KEY) {
          return mapper[key as T]!.map((operator) => {
            return operator.handler(
              createSelector(source as any, ofType(testSpecialCase))
            );
          });
        }

        if (key === REACTION_KEY) {
          return mapper[key as T]!.map((operator) => {
            return operator.handler(
              createSelector(source as any, ofTypeReaction(testReactionCase))
            );
          });
        }

        return mapper[key as T]!.map((operator) => {
          return operator.handler(
            createSelector(
              source as any,
              ofType(not(testSpecialCase), testActionType(key))
            )
          );
        });
      })
      .flat()
  ) as Observable<Action>;
};
