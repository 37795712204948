import { createStore } from 'effector';
import {
  addContextData,
  clearAllContextData,
  deleteContextData,
  updateContextData,
  updateContextDataFilterOperation,
  updateContextDataMultiplyOperation,
  updateContextDataSetOperation,
  updateContextDataSubtractOperation,
  updateContextDataSumOperation
} from './Events';
import {
  onAddContextData,
  onClearAllContextData,
  onDeleteContextData,
  onUpdateContextData,
  onUpdateContextDataFilterOperation,
  onUpdateContextDataMultiplyOperation,
  onUpdateContextDataSetOperation,
  onUpdateContextDataSubtractOperation,
  onUpdateContextDataSumOperation
} from './events/index';
import AppState from './State';

const initialState: AppState = new AppState();

const AppStore = createStore(initialState)
  .on(addContextData, onAddContextData)
  .on(updateContextData, onUpdateContextData)
  .on(deleteContextData, onDeleteContextData)
  .on(clearAllContextData, onClearAllContextData)
  .on(updateContextDataSetOperation, onUpdateContextDataSetOperation)
  .on(updateContextDataMultiplyOperation, onUpdateContextDataMultiplyOperation)
  .on(updateContextDataSubtractOperation, onUpdateContextDataSubtractOperation)
  .on(updateContextDataSumOperation, onUpdateContextDataSumOperation)
  .on(updateContextDataFilterOperation, onUpdateContextDataFilterOperation);

export default AppStore;
