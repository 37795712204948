import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { IAMAbstractService } from '../../services/IAM/IAM.abstract.service';

export interface IGetAccessTokenParams {
  state: string;
  code: string;
}

export class ActionsForgotPasswordHandler
  implements IEventHandler<IGetAccessTokenParams>
{
  constructor(private IAMService: IAMAbstractService) { }

  handler(
    source: Observable<Action<'action', IGetAccessTokenParams>>
  ): Observable<Action<'action', IGetAccessTokenParams>> {
    return source.pipe(
      ofType(
        (action) => action.url === ActionsUrl.OPEN_FORGOT_PASSWORD_JOURNEY
      ),
      concatMap(async (e) => {
        await this.IAMService.openForgotPasswordJourney();
        return e;
      })
    );
  }
}

