import { Action, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';

export class ActionsVoidHandler {
  constructor() {}

  handler(
    source: Observable<Action<'action', any>>
  ): Observable<Action<'action', any>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.VOID),
      concatMap(async (e) => {
        return e;
      })
    );
  }
}
