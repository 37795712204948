export const setValueToPath = (object: any, value: any, path: string) => {
  const paths = path.split('.');

  let i;

  for (i = 0; i < paths.length - 1; i++) object = object[paths[i]];

   if (!object) {
    return;
  }

  if (object) {
    object[paths[i]] = value;
  }
};
