/* eslint-disable react-hooks/exhaustive-deps */
import { ObjectParser } from '@bees-lite-web/hornet-web';
import { useStoreMap } from 'effector-react';
import { memo, useEffect } from 'react';
import AppState from '../../store/State';
import AppStore from '../../store/Store';
import setup from './dynamic-store-utils/setup';

interface Props {
  renderData: any;
  $replacement?: boolean | undefined;
  componentKey?: string | undefined;
}

export const DynamicRender = memo(
  ({ renderData, $replacement, componentKey }: Props) => {
    const [contextData] = useStoreMap({
      store: AppStore,
      keys: [],
      fn: (state: AppState) => [state.data],
    });

    useEffect(() => {
      setup(renderData);
    }, [renderData]);

    return (
      <ObjectParser
        contextData={contextData}
        json={renderData}
        {...($replacement ? { $replacement } : {})}
        {...(componentKey ? { key: componentKey } : {})}
      ></ObjectParser>
    );
  }
);
