import { environment } from '../../../environments/environment';

export const ROUTES = {
  config: () => environment.keeperUrl + '/config/core',
  offline: (countryCode: string, language: string) =>
    environment.keeperUrl + `/config/${countryCode}/${language}/offline`,
  login: (countryCode: string, language: string) =>
    environment.keeperUrl + `/authentication/${countryCode}/${language}`,
  reauth: (countryCode: string, language: string) =>
    environment.keeperUrl + `/refresh/${countryCode}/${language}`,
  identify: (countryCode: string, language: string) =>
    environment.keeperUrl + `/identify-payload/${countryCode}/${language}`,
};
