import { AppTourElement } from '../../../services/keeper/keeper.payload.interface';
import getRootElement from './getRootElement';

const getElement = (
  elementSelector: AppTourElement | string,
  firstCall: boolean = true,
  baseRoot: any = window.document
): any => {
  if (!elementSelector || (!firstCall && !baseRoot)) return null;

  const selector =
    typeof elementSelector === 'string'
      ? elementSelector
      : `[${elementSelector.selectorName}="${elementSelector.selectorValue}"]`;

  if (firstCall && typeof elementSelector !== 'string') {
    return getElement(selector, true, getRootElement('#root', window.document));
  } else if (firstCall) {
    return getElement(
      selector,
      false,
      getRootElement(selector, window.document)
    );
  }

  const child =
    baseRoot.shadowRoot &&
    baseRoot.shadowRoot?.children?.item(0)?.tagName !== 'SLOT'
      ? baseRoot.shadowRoot?.children?.item(0)
      : baseRoot.children?.item(0);

  const childName = child?.tagName?.toLowerCase();

  const childWidth = (child as HTMLElement)?.offsetWidth;
  const childDisplay = (child as HTMLElement)?.style?.display;

  if (
    (childName &&
      (childDisplay === 'contents' ||
        (!childWidth && child?.children?.length))) ||
    !childWidth
  ) {
    return getElement(childName as string, false, child);
  } else if (childDisplay !== 'contents' && childWidth) {
    return child;
  } else {
    return null;
  }
};

export default getElement;
