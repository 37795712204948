import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { StorageAbstract } from '../../core/storage/storage.abstract';

export interface ISaveUserPreferenceParams {
  key: string;
  value: string;
  reload: boolean;
}

export class ActionsSaveUserPreferenceHandler
  implements IEventHandler<ISaveUserPreferenceParams>
{
  constructor(private store: StorageAbstract) {}

  handler(
    source: Observable<Action<'action', ISaveUserPreferenceParams>>
  ): Observable<Action<'action', ISaveUserPreferenceParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.SAVE_USER_PREFERENCE),
      concatMap(async (e: any) => {
        const userPreference = (await this.store.get('userPreference')) ?? {};

        if (userPreference) {
          await this.store.set('userPreference', {
            ...userPreference,
            [e.parameters.key]: e.parameters.value,
          });
        }

        e.parameters.reload !== false && window.location.reload();

        return e;
      })
    );
  }
}
