const processJsonElement = (
  contextData: any,
  jsonElement: any,
  setupStore: any
) => {
  const { body, appBar, bottomBar, sidebar } = jsonElement;
  const promiseList: any = [];

  appBar &&
    promiseList.push(
      setupStore(contextData, { ...appBar, key: 'appBar' }, promiseList)
    );
  sidebar &&
    promiseList.push(
      setupStore(contextData, { ...sidebar, key: 'sidebar' }, promiseList)
    );
  bottomBar &&
    promiseList.push(
      setupStore(contextData, { ...bottomBar, key: 'bottomBar' }, promiseList)
    );
  if (body) {
    for (const element of body) {
      promiseList.push(setupStore(contextData, element, promiseList));
    }
  }

  return promiseList;
};

export default processJsonElement;
