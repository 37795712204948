import { IEventHandler, ofType } from '@bees-lite-web/core';
import { ObjectParser } from '@bees-lite-web/hornet-web';
import { render } from 'react-dom';
import { tap } from 'rxjs/operators';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export const generateUuid = () => {
  const a = new Uint32Array(3);
  window.crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '');
};

export class ActionsPartialUpdatesHandler
  implements IEventHandler<KeeperPayload>
{
  handler(source: any): any {
    return source.pipe(
      ofType((e: any) => e.url === 'exclusivePartial'),
      tap(async (e: any) => {
        try {
          e.parameters!.action!.partialUpdates?.forEach((update: any) => {
            render(
              <ObjectParser
                contextData={{}}
                key={generateUuid()}
                json={update.widget}
                $replacement={true}
              />,
              document.querySelector(`div[node-id="${update.nodeId}"]`)
            );
          });
        } catch (error) {
          return e;
        }
      })
    );
  }
}
