import { BaseContext } from '@bees-lite-web/core';
import { createEvent } from 'effector';

export const addContextData = createEvent<BaseContext | BaseContext[]>(
  'addContextData'
);

export const updateContextData = createEvent<BaseContext>('updateContextData');

export const updateContextDataSetOperation = createEvent<any>(
  'updateContextDataSetOperation'
);

export const updateContextDataSumOperation = createEvent<any>(
  'updateContextDataSumOperation'
);

export const updateContextDataSubtractOperation = createEvent<any>(
  'updateContextDataSubtractOperation'
);

export const updateContextDataMultiplyOperation = createEvent<any>(
  'updateContextDataMultiplyOperation'
);

export const updateContextDataFilterOperation = createEvent<any>(
  'updateContextDataFilterOperation'
);

export const deleteContextData = createEvent<string>('delete');

export const getContextData = createEvent<string>('get');

export const getAllContextData =
  createEvent<{ [key: string]: BaseContext }>('getAll');

export const clearAllContextData = createEvent('clearAll');
