import { BaseContext } from '../../../../../../../libs/core/src';
import processChildElements from './processChildElements';
import processJsonElement from './processJsonElement';

const setupStore = (
  _contextData: BaseContext[],
  json: any,
  promiseList: any[]
) =>
  new Promise<any>((resolve) => {
    if (!json) {
      resolve(true);
      return;
    }
    const { parameters, type, key } = json;

    if (parameters?.stateId) {
      _contextData.push(
        new BaseContext(parameters?.stateId, parameters, parameters?.keepState)
      );
    }

    if (type === 'page') {
      promiseList.push(...processJsonElement(_contextData, json, setupStore));
    }

    if (parameters?.children && parameters?.children?.length) {
      const childPromises = processChildElements(
        _contextData,
        parameters.children,
        setupStore
      );
      promiseList.push(...childPromises);
    } else if (parameters?.child) {
      promiseList.push(setupStore(_contextData, parameters.child, promiseList));
    }
    resolve(true);
  });

export default setupStore;
