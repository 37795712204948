import { concat, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Nullable } from '../interfaces/maybe.type';
import { StorageAbstract } from './storage.abstract';

export class StorageSessionStorage extends StorageAbstract {
  private _subject = new Subject<{
    key: Nullable<string>;
    value: Nullable<any>;
  }>();

  async get(key: string): Promise<any> {
    const obj = sessionStorage.getItem(key);
    if (obj) return JSON.parse(obj);
  }

  async set(key: string, value: any): Promise<void> {
    sessionStorage.setItem(key, JSON.stringify(value));
    this._subject.next({ key, value });
  }

  watch(key: string): Observable<any> {
    return concat(
      this.get(key),
      this._subject.asObservable().pipe(
        filter((e) => e.key === key || e.key === null),
        map((e) => e.value)
      )
    );
  }

  async remove(key: string): Promise<void> {
    sessionStorage.removeItem(key);
    this._subject.next({ key, value: null });
  }

  async clear(): Promise<void> {
    sessionStorage.clear();
    this._subject.next({ key: null, value: null });
  }
}
