import { ResponseInfraInterface } from './response.infra.interface';

export abstract class RequesterInfraAbstract {
  abstract get<T>(
    url: string,
    options?: any
  ): Promise<ResponseInfraInterface<T>>;
  abstract post<T>(
    url: string,
    body: any,
    options?: any
  ): Promise<ResponseInfraInterface<T>>;
  abstract patch<T>(
    url: string,
    body: any,
    options?: any
  ): Promise<ResponseInfraInterface<T>>;
  abstract put<T>(
    url: string,
    body: any,
    options?: any
  ): Promise<ResponseInfraInterface<T>>;
  abstract delete<T>(
    url: string,
    options?: any
  ): Promise<ResponseInfraInterface<T>>;
  abstract configureHeader(headers: Record<string, string>): void;
}
