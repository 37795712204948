import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
export interface ICopyToClipboardParams {
  value: string;
}
export class ActionsCopyToClipboardHandler
  implements IEventHandler<ICopyToClipboardParams>
{
  handler(
    source: Observable<Action<'action', ICopyToClipboardParams>>
  ): Observable<Action<'action', ICopyToClipboardParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.COPY_TO_CLIPBOARD),
      concatMap(async (e) => {
        try {
          await navigator.clipboard.writeText(e?.parameters?.value ?? '');
        } catch (error) {
          console.error('Failed to copy to clipboard:', error);
        }
        return e;
      })
    );
  }
}
