import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';

interface ILaunchURLParams {
  url: string;
}

export class ActionsReloadPageHandler
  implements IEventHandler<ILaunchURLParams>
{
  handler(
    source: Observable<Action<'action', ILaunchURLParams>>
  ): Observable<Action<'action', ILaunchURLParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.RELOAD_PAGE),
      tap(async (e) => {
        window.location.reload();
      })
    );
  }
}
