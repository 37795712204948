export const HealthCheckScreen = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h3
        style={{
          display: 'inline-block',
          fontSize: '48px',
          color: '#000000aa',
        }}
      >
        Hey There!!! The App is Healthy
      </h3>
    </div>
  );
};
