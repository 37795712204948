import { ExpressionTypes } from './types/expressionTypes';
import { HornetOperations } from './types/hornetOperations';

export class ExpressionModel {
  operation: HornetOperations;
  type: ExpressionTypes;
  value: string;

  constructor(
    operation: HornetOperations,
    value: string,
    type: ExpressionTypes
  ) {
    this.operation = operation;
    this.type = type;
    this.value = value;
  }
}
