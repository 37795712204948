import { Action } from '@bees-lite-web/core';
import { useEffect } from 'react';
import { emmiter, MELLIFERA_EVENT_TYPE } from '../../core/eventContext';
import { StorageAbstract } from '../../core/storage/storage.abstract';

type Props = {
  storage: StorageAbstract;
};

export const NotFoundScreen = (props: Props) => {
  useEffect(() => {
    (async () => {
      const { storage } = props;
      const auth = await storage.get('auth');
      const initialPublic = await storage.get('initialPublic');
      const initialPrivate = await storage.get('initialPrivate');

      emmiter.next(
        new Action({
          type: MELLIFERA_EVENT_TYPE.navigation,
          eventTrigger: 'loadingPage',
          url: auth ? initialPrivate : initialPublic,
        })
      );
    })();
  }, [props]);

  return null;
};
