export enum ActionsUrl {
  LOGIN = 'action:login',
  LOGOUT = 'action:logout',
  LAUNCH_URL = 'action:launch_url',
  RELOAD_PAGE = 'action:reload_page',
  CHANGE_LOCALE = 'action:change_locale',
  CHANGE_LANGUAGE = 'action:change_language',
  GET_ACCESS_TOKEN = 'action:get_access_token',
  SAVE_SELECTED_POC = 'action:save_selected_poc',
  OPEN_OAUTH_JOURNEY = 'action:open_oauth_journey',
  OPEN_REGISTRATION_JOURNEY = 'action:open_registration_journey',
  OPEN_FORGOT_PASSWORD_JOURNEY = 'action:forgot_password_journey',
  GO_BACK = 'action:go_back',
  UPDATE_PAGE_PARAMETERS = 'action:update_page_parameters',
  SAVE_USER_PREFERENCE = 'action:save_user_preference',
  TRACK = 'action:track',
  NOTIFICATION_AUDIO = 'action:notification_audio',
  VOID = 'action:void',
  SOCKET_CONNECTION = 'action:socket_connection',
  COPY_TO_CLIPBOARD = 'action:copy_to_clipboard',
}
