import { Reaction } from './base.reaction';

export interface IActionParams<A, T = unknown> {
  eventTrigger?: string;
  type?: A;
  url?: string;
  parameters?: T;
  callback?: (...args: any[]) => any;
}

export class Action<A = string, T = unknown> implements IActionParams<A, T> {
  eventTrigger?: string;
  type?: A;
  url?: string;
  reactions?: Array<Reaction>;
  parameters?: T;
  callback?: (...args: any[]) => any;

  constructor(parameters: IActionParams<A>) {
    this.eventTrigger = parameters.eventTrigger;
    this.type = parameters.type ?? ('navigation' as any);
    this.url = parameters.url;
    this.parameters = parameters.parameters as T;
    this.callback = parameters.callback;
  }
}
