/* eslint-disable react-hooks/exhaustive-deps */
import { Action } from '@bees-lite-web/core';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import history from '../../core/browserHistory';
import { MELLIFERA_EVENT_TYPE, emmiter } from '../../core/eventContext';
import { navigationExtraParams } from '../../core/navigationExtraParams.subject';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import {
  AppTour,
  KeeperPayload,
} from '../../services/keeper/keeper.payload.interface';

import { useOfflinePageContext } from '../../context/OfflinePageContext';
import { DynamicRender } from './DynamicRender';
import startAppTour from './app-tour/startAppTour';

type Props = {
  url: string;
  processedUrl: string;
  path: string;
  private: boolean;
  type: string;
  skeleton: string;
  storage: StorageAbstract;
  timestamp: Subject<number>;
  page?: Subject<KeeperPayload>;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const DynamicScreen = memo((props: Props) => {
  const [skeleton, setSkeleton] = useState<any>({});
  const [page, setPage] = useState<KeeperPayload>();
  const [timestamp, setTimestamp] = useState<number>();
  const { countryCode = 'US', language = 'en-US' } = useParams<any>();
  const lastLocation = useRef('');
  const { location } = history;
  const scrolling = useRef(false);
  const navigationParams = useRef<any>(null);
  const queryString = useQuery();
  const { isOnline, offlinePage } = useOfflinePageContext();

  // handleAnchorsNavigation                    //  NOSONAR
  useEffect(() => {
    const { hash } = location;
    if (hash && page?.render?.[0] && scrolling.current === false) {
      scrolling.current = true;
      setTimeout(() => {
        const el = document.querySelector(`[hash="${hash.replace('#', '')}"]`);
        el?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
        scrolling.current = false;
      }, 100);
    }
  });

  // handleBackButtonDisplaing                    //  NOSONAR
  useEffect(() => {
    const sub = props.page
      ?.pipe(
        mergeMap((page) => [undefined, page]),
        mergeMap(applyBackButtom)
      )
      .subscribe(setPage);
    return () => sub?.unsubscribe();
  }, [props.page, setPage]);

  // handlePageRenderTimestampChange                    //  NOSONAR
  useEffect(() => {
    const sub = props.timestamp.subscribe(setTimestamp);
    return () => sub?.unsubscribe();
  }, [props.timestamp, setTimestamp]);

  // applingSkeleton                    //  NOSONAR
  useEffect(() => {
    (async () => {
      if (!props.skeleton) return;

      const skeletons = await props.storage.get('skeletons');
      const skeleton = skeletons?.find((e: any) => e.name === props.skeleton);

      if (skeleton) setSkeleton(skeleton);
    })();
  }, [props.skeleton, props.storage]);

  // handleOfflineUser when the user backs online   //  NOSONAR
  useEffect(() => {
    const handleOnline = () => {
      window.location.reload();
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  // listenExtraParams                    //  NOSONAR
  useEffect(() => {
    const sub = navigationExtraParams
      .pipe(tap((parameters) => (navigationParams.current = parameters)))
      .subscribe();
    return () => sub.unsubscribe();
  }, []);

  // getPageContentWhenURLChanges                    //  NOSONAR
  useEffect(() => {
    const realLocation = location.pathname + location.search;

    if (!countryCode || !language) return;
    if (countryCode === 'undefined' || language === 'undefined') return;
    if (realLocation === lastLocation.current) return;

    lastLocation.current = realLocation;

    emmiter.next(
      new Action({
        type: MELLIFERA_EVENT_TYPE.get,
        eventTrigger: 'loadingPage',
        url: (
          navigationParams.current?.replaceProcessedUrl ?? props.processedUrl
        )
          .replace('{countryCode}', countryCode)
          .replace('{language}', language),
      })
    );

    navigationExtraParams.next(null);
  }, [
    countryCode,
    language,
    props.processedUrl,
    timestamp,
    location,
    page,
    queryString,
  ]);

  useEffect(() => {
    startAppTour(page?.action?.appTour as AppTour);
  }, [page]);

  const applyBackButtom = async (page: any) => {
    const initialPrivate = await props.storage.get('initialPrivate');
    if (!page?.render?.[0]) return page;
    if (props.path === initialPrivate) return page;
    if (!page.render[0].appBar) return page;
    if (page.render[0].appBar.parameters.hasBackButtom) return page;

    page.render[0].appBar.parameters.hasBackButtom = true;
    return { ...page };
  };

  return (
    <DynamicRender
      renderData={
        !isOnline
          ? offlinePage?.render?.[0]
          : page?.render?.[0] ?? skeleton.render?.[0]
      }
    />
  );
});
