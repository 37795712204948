import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import history from '../../core/browserHistory';
import { MELLIFERA_EVENT_TYPE, emmiter } from '../../core/eventContext';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

export class ReadyHandler implements IEventHandler {
  constructor(
    private bffService: KeeperAbstractService,
    private storage: StorageAbstract
  ) {}

  handler(source: Observable<Action<'ready'>>): Observable<Action<'ready'>> {
    return source.pipe(concatMap((e) => this.configure(e)));
  }

  async configure(e: Action<'ready'>): Promise<any> {
    try {
      const config = await this.bffService.configure();

      const country = (await this.storage.get('country')) || 'US';
      const language = await this.storage.get('language');

      if (!country || !language || window.location.pathname === '/') {
        if (
          !window.location.href.endsWith(
            config.action?.save?.initialPublicConfig
          )
        ) {
          history.replace(
            `/${config.action?.save?.availableCountries?.[0]}/${config.action?.save?.availableLanguages?.[0]}${config.action?.save?.initialPublicConfig}`
          );
        }

        return e;
      }

      const pageOffline = await this.storage.get('pageOffline');

      if (!pageOffline) {
        await this.bffService.getOffilinePages();
      }

      return e;
    } catch (error) {
      emmiter.next(
        new Action({
          type: MELLIFERA_EVENT_TYPE.error,
          eventTrigger: 'ajaxError',
          parameters: {
            error,
          },
        })
      );

      return e;
    }
  }
}
