import { BaseContext } from '@bees-lite-web/core';
import AppState from '../State';

export const onClearAllContextData = (state: AppState) => {
  const newDataList: BaseContext[] = Object.values(state.data).filter(
    (context) => {
      return !!context?.data?.keepState;
    }
  );

  const newState: AppState = new AppState(Object.assign({}, ...newDataList));
  
  return newState;
};
