import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, ReactNode, useContext, useMemo } from 'react';

const AnalyticsContext = createContext<AnalyticsBrowser | undefined>(
  undefined
);

type Props = {
  writeKey: string;
  children: ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = useMemo(() => {
    if (writeKey) return AnalyticsBrowser.load({ writeKey });
    return undefined;
  }, [writeKey]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    console.error(
      'Context used outside of its Provider or writeKey not provided!'
    );
  }
  return result;
};
