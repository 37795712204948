import { BaseContext } from '@bees-lite-web/core';

class AppState {
  data: { [key: string]: BaseContext };

  constructor(data: { [key: string]: BaseContext } = {}) {
    this.data = data;
  }

  getContext(key: string) {
    return this?.data?.[key]
  }
}

export default AppState;
