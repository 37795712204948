import { Action, EventBus, Reaction } from '@bees-lite-web/core';
import { Subject } from 'rxjs';

enum KEEPER_ACTIONS {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
  patch = 'patch',
  navigation = 'navigation',
  productProvider = 'productProvider',
  search = 'search',
  action = 'action',
  reaction = 'reaction',
}

enum COMPONENTS_ACTIONS {
  ready = 'ready',
  scroll = 'scroll',
}

export const ACTION_TYPE = {
  ...KEEPER_ACTIONS,
  ...COMPONENTS_ACTIONS,
};
export type ACTION_TYPE = COMPONENTS_ACTIONS | KEEPER_ACTIONS;

export let emmiter: Subject<Action<ACTION_TYPE> | Reaction>;
export const eventContext = new EventBus<ACTION_TYPE>((sub) => (emmiter = sub));
