import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export class ResponseActionsSessionSaveHandler
  implements IEventHandler<KeeperPayload>
{
  constructor(private sessionStorage: StorageAbstract) {}

  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType(
        (e) =>
          !!e.parameters &&
          !!e.parameters.action &&
          !!e.parameters.action.sessionSave &&
          !e.parameters.action.sessionSave?.redirectTo
      ),
      concatMap(async (e) => {
        const saveObject = e.parameters!.action!.sessionSave!;

        await Promise.all(
          Object.keys(saveObject).map((key) => {
            return this.sessionStorage.set(key, saveObject[key]);
          })
        );

        return e;
      })
    );
  }
}
