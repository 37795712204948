import {
  applyPolyfills,
  defineCustomElements,
} from '@bees-lite-web/honeycomb-web/loader';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './app/app';
import { AnalyticsProvider } from './app/context/AnalyticsContext';
import history from './app/core/browserHistory';
import { startPersistentNotificationServices } from './persistent-notifications/services-initializer';
import { getEnvCountryFromStorage } from './utils/getEnvCountryFromStorage';

startPersistentNotificationServices();

ReactDOM.render(
  <StrictMode>
    <Router history={history}>
      <AnalyticsProvider writeKey={getEnvCountryFromStorage()}>
        <App />
      </AnalyticsProvider>
    </Router>
  </StrictMode>,
  document.getElementById('root')
);

applyPolyfills().then(() => {
  defineCustomElements();
});
