import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

export interface IChangeLocaleParams {
  country: string;
  language: string;
}

export class ActionsChangeLocaleHandler
  implements IEventHandler<IChangeLocaleParams>
{
  constructor(
    private bffService: KeeperAbstractService,
    private store: StorageAbstract
  ) {}

  handler(
    source: Observable<Action<'action', IChangeLocaleParams>>
  ): Observable<Action<'action', IChangeLocaleParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.CHANGE_LOCALE),
      concatMap(async (e) => {
        const { country, language } = e.parameters!;
        await Promise.all([
          this.store.set('language', language),
          this.store.set('country', country),
        ]);
        window.location.href = '/';

        return e;
      })
    );
  }
}
