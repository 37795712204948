import { Action } from '@bees-lite-web/core';
import { eventContext, emmiter, ACTION_TYPE } from './events/eventContext';

document.addEventListener('DOMContentLoaded', () => {
  emmiter.next(
    new Action({
      type: ACTION_TYPE.ready,
      eventTrigger: 'DOMContentLoaded',
    })
  );
});

export { eventContext };
export * from './objectParser';
