import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';

interface ITrackParams {
  url: string;
  eventName: string;
  analyticsData: any;
}

export class ActionsTrackHandler implements IEventHandler<ITrackParams> {
  constructor(private analytics: AnalyticsBrowser | undefined) {}

  handler(
    source: Observable<Action<'action', ITrackParams>>
  ): Observable<Action<'action', ITrackParams>> {
    return source.pipe(
      ofType((action) => action.url === ActionsUrl.TRACK),
      tap(async (e) => {
        const analyticsData = JSON.parse(e.parameters!.analyticsData);
        const eventName = analyticsData.name;

        delete analyticsData.name;

        if (this.analytics) {
          await this.analytics.track(eventName, analyticsData);
        }
      })
    );
  }
}
