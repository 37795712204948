import { PersistentNotificationService } from './notification.service';
import { MelliferaPersistentNotificationServiceSettings } from './worker.interface';

const retryTimeout = 120000; // 2 minutes in milliseconds

class WorkerManager {
  private worker!: Worker;
  private services: any;

  async startWorker() {
    console.log('Starting startWorker');
    this.worker = new Worker(`/mellifera-worker.js?t=${Math.random()}`);
    this.initializeListeners();
    setTimeout(() => this.initializeServices(), 10000);
  }

  private initializeListeners() {
    this.worker.addEventListener('message', (event) => {
      if (event.data.type === 'UNEXPECTED_ERROR') {
        this.handleNotificationRetry();
      }
    });
    this.worker.addEventListener('error', (event) => {
      this.handleNotificationRetry();
    });
  }

  private initializeServices() {
    console.log('Initializing services...');
    const notificators = JSON.parse(
      localStorage.getItem('notificationService') || '[]'
    );
    this.services = notificators.map(
      (
        settings: MelliferaPersistentNotificationServiceSettings,
        idx: number
      ) => {
        settings.recipient = `${settings.recipient}-${idx}`;
        return new PersistentNotificationService(this.worker, settings);
      }
    ) as any;

    this.worker.onmessage = async (message) => {
      await this.services.forEach(
        async (service: MelliferaPersistentWorker) =>
          await service.messageHandler(message)
      );
    };
  }

  private handleNotificationRetry() {
    console.log('Retrying notification worker in 2 minutes...');
    setTimeout(() => this.restartWorker(), retryTimeout);
  }

  private restartWorker() {
    console.log('Restarting worker...');
    if (this.worker) {
      console.log('Terminating worker...');
      this.worker.terminate();
    }
    this.startWorker();
  }
}

export async function startPersistentNotificationServices() {
  const worker = new WorkerManager();
  console.log('Starting persistent notification services...');
  await worker.startWorker();
}
