import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';

export class PatchHandler implements IEventHandler {
  constructor(private bffService: KeeperAbstractService) {}

  handler(
    source: Observable<Action<'patch', any>>
  ): Observable<Action<'patch'>> {
    return source.pipe(
      concatMap(async (e) => {
        await this.bffService.patch(e.url!, e.parameters);
        return e;
      })
    );
  }
}
