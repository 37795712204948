import { useEffect } from 'react';

export const LogoutScreen = () => {
  useEffect(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = '/';
  }, []);
  return null;
};
