import { BaseContext } from '../../../../../../../libs/core/src';

const processChildElements = (
  contextData: BaseContext[],
  childElements: any,
  setupStore: any
) => {
  const promiseList: any = [];

  for (const child of childElements) {
    if (child) {
      if (Array.isArray(child)) {
        for (const innerChild of child) {
          promiseList.push(setupStore(contextData, innerChild, promiseList));
        }
      } else {
        promiseList.push(setupStore(contextData, child, promiseList));
      }
    }
  }

  return promiseList;
};

export default processChildElements;
