import { BaseContext, Reaction } from '@bees-lite-web/core';
import AppState from '../State';
import { deletePropertyByPath } from '../utils/deletePropertyByPath';
import { getValueByPath } from '../utils/getValueByPath';
import { setKeepedState } from '../utils/setKeepedState';
import { setValueToPath } from '../utils/setValueToPath';

const subtractString = (value: string, complement: string) =>
  `${value}`.split(complement).join('');

const subtractNumber = (value: number, complement: number) =>
  parseFloat(`${value || 0}`) - parseFloat(`${complement || 0}`);

const subtractObject = (data: any, path: string) =>
  deletePropertyByPath(data, path);

const subtractArray = (value: Array<any>, complement: number) => {
  const newValue = [...value];
  newValue.splice(complement, 1);
  return newValue;
};

export const onUpdateContextDataSubtractOperation = (
  state: AppState,
  reaction: Reaction
) => {
  const newData: {
    [key: string]: BaseContext;
  } = { ...state.data };
  const newState: AppState = new AppState(newData);

  if (!newData[reaction.targetId]?.data) return state;

  const value = getValueByPath(newData[reaction.targetId].data, reaction.path);

  let newValue;

  const setNewValue = (method: (value: any, complement: any) => any) => {
    newValue = method(value, reaction.expression.value);
  };

  switch (reaction.expression.type) {
    case 'string':
      setNewValue(subtractString);
      break;
    case 'number':
      setNewValue(subtractNumber);
      break;
    case 'object':
      subtractObject(newData[reaction.targetId].data, reaction.path);
      break;
    case 'array':
      setNewValue(subtractArray);
      break;
  }

  setValueToPath(newData[reaction.targetId].data, newValue, `${reaction.path}`);
  if (newState.data[reaction.targetId].keepState) {
    setKeepedState(newState.data[reaction.targetId]);
  }
  return newState;
};
