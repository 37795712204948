import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { displayMessageSubject } from '../../core/displayMessage.subject';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export class ResponseActionsMessageHandler
  implements IEventHandler<KeeperPayload>
{
  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType((e) => !!e.parameters && !!e.parameters.action?.message),
      tap(async (e) => {
        displayMessageSubject.next({ message: e.parameters!.action!.message!, interval: 3.5})
      })
    );
  }
}
