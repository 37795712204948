import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { ObjectParser } from '@bees-lite-web/hornet-web';
import { render } from 'react-dom';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import startAppTour from '../../screens/dynamic/app-tour/startAppTour';
import setup from '../../screens/dynamic/dynamic-store-utils/setup';
import {
  AppTour,
  KeeperActionPartialUpdates,
  KeeperPayload,
} from '../../services/keeper/keeper.payload.interface';

export const generateUuid = () => {
  const a = new Uint32Array(3);
  window.crypto && window.crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '');
};

function findAndUpdateObjectByKey(
  obj: any,
  targetKey: any,
  newObject: any,
  path: any = []
) {
  if (obj && typeof obj === 'object') {
    if (obj?.parameters?.nodeId === targetKey) {
      Object.assign(obj, newObject);
      return true;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          findAndUpdateObjectByKey(obj[key], targetKey, newObject, [
            ...path,
            key,
          ])
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

export class ResponseActionsPartialUpdatesHandler
  implements IEventHandler<KeeperPayload>
{
  constructor(
    private backendPayload: React.MutableRefObject<ReplaySubject<KeeperPayload>>
  ) {}

  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType(
        (e) =>
          !!e.parameters &&
          !!e.parameters.action &&
          !!e.parameters.action.partialUpdates &&
          !!e.parameters.action.partialUpdates.length &&
          !!e.parameters.action.partialUpdates.length
      ),
      tap(async (e) => {
        e.parameters!.action!.partialUpdates?.forEach(async (update) => {
          this.handleSpecialCases(update);

          if (e.parameters?.action?.appTour) {
            startAppTour(e.parameters?.action?.appTour as AppTour);
          }

          if (update.updatePayload) {
            this.backendPayload.current
              .pipe(debounceTime(0), take(1))
              .subscribe((value) => {
                const payload = { ...value };

                const success = findAndUpdateObjectByKey(
                  payload,
                  update.nodeId,
                  {
                    ...update.widget,
                    parameters: {
                      ...update.widget.parameters,
                      nodeId: update.nodeId,
                    },
                  }
                );
                if (success) {
                  this.backendPayload.current.next(payload);
                } else {
                  console.error(
                    `The object with the nodeId ${update.nodeId} was not found in the payload`
                  );
                }
              });
          } else {
            if (JSON.stringify(update).includes('stateId'))
              await setup(update.widget);

            setTimeout(() => {
              const elToReplace = document.querySelector(
                `div[node-id="${update.nodeId}"]`
              );
              if (!elToReplace) {
                console.error(
                  `The element with the nodeId ${update.nodeId} was not found in the DOM`
                );
                return;
              }
              render(
                <ObjectParser
                  contextData={{}}
                  key={generateUuid()}
                  json={{
                    ...update.widget,
                    parameters: {
                      ...update.widget.parameters,
                      nodeId: update.nodeId,
                    },
                  }}
                  $replacement={true}
                />,
                elToReplace
              );
            }, 100);
          }
        });
      })
    );
  }

  private handleSpecialCases(update: KeeperActionPartialUpdates) {
    if (update.widget.type === 'appBar') {
      return this.handleSpecialCaseForAppBar(update);
    }
  }

  private handleSpecialCaseForAppBar(update: KeeperActionPartialUpdates) {
    const hasbackbuttom = document
      .querySelector(`div[node-id="${update.nodeId}"] bees-app-bar`)
      ?.getAttribute('hasbackbuttom');

    if (hasbackbuttom) {
      (update.widget.parameters! as any).hasBackButtom = true;
    }
  }
}
