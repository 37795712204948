import { BaseContext } from '@bees-lite-web/core';
import AppState from '../State';
import { setKeepedState } from '../utils/setKeepedState';

export const onAddContextData = (
  state: AppState,
  context: BaseContext | BaseContext[]
) => {
  const newState: AppState = new AppState(state.data);

  if (!context || !(context as BaseContext[]).length) return state;

  if (Array.isArray(context)) {
    const data: { [key: string]: BaseContext } = {};
    for (const ctx of context) {
      data[ctx.id] = new BaseContext(ctx.id, ctx.data, ctx.keepState);

      setKeepedState(data[ctx.id]);
    }
    newState.data = { ...newState.data, ...data };
  } else {
    const newContext = new BaseContext(
      context.id,
      context.data,
      context.keepState
    );

    setKeepedState(newContext);

    newState.data = {
      ...newState.data,
      [context.id]: newContext,
    };
  }
  return newState;
};
