'use client';

import { useReducer } from 'react';
import { environment } from '../../../../../environments/environment';
import {
  KeeperDebugHandler,
  deleteDebugHeader,
  getDebugHeaders,
} from './actions/keeperDebug.actions';

const formInitialState = {
  url: environment.keeperUrl,
  headerKey: '',
  headerValue: '',
};

function formReducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_URL':
      return { ...state, url: action.payload };
    case 'SET_HEADER_KEY':
      return { ...state, headerKey: action.payload };
    case 'SET_HEADER_VALUE':
      return { ...state, headerValue: action.payload };
    default:
      return state;
  }
}

const KeeperDebug = () => {
  const [formState, dispatch] = useReducer(formReducer, formInitialState);
  const debugHeaders = getDebugHeaders();

  function handleInputChange(event: any, type: string) {
    dispatch({
      type: type,
      payload: event.target.value,
    });
  }

  function handleFormSubmit(event: any) {
    event.preventDefault();
    KeeperDebugHandler(formState);
  }

  return (
    <>
      <h4
        style={{
          color: 'purple',
          fontSize: '16px',
          marginBottom: '8px',
        }}
      >
        Debug Headers
      </h4>
      {debugHeaders && Object.keys(debugHeaders).length > 0 ? (
        <ul style={{ marginBottom: '8px' }}>
          {Object.keys(debugHeaders).map((header) => (
            <li key={header}>
              {header}: {debugHeaders[header]}
              <span
                data-testid="keeper-debug-delete-button"
                style={{
                  color: 'red',
                  marginLeft: '4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  deleteDebugHeader(header);
                }}
              >
                X
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <span
          style={{
            color: 'gray',
            fontSize: '14px',
            marginBottom: '8px',
          }}
        >
          No debug headers found
        </span>
      )}
      <h4
        style={{
          color: 'purple',
          fontSize: '16px',
          marginBottom: '8px',
        }}
      >
        Edit Keeper request
      </h4>
      Keeper URL:
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '16px',
        }}
      >
        <input
          data-testid="keeper-url-input"
          required
          name="keeper-url"
          value={formState.url}
          onChange={(e) => handleInputChange(e, 'SET_URL')}
          type="text"
          style={{ marginBottom: '6px' }}
        />
        <input
          data-testid="keeper-header-key-input"
          name="keeper-header-key"
          value={formState.headerKey}
          onChange={(e) => handleInputChange(e, 'SET_HEADER_KEY')}
          placeholder="Header Key"
          style={{ marginBottom: '4px' }}
          type="text"
        />
        <input
          data-testid="keeper-header-value-input"
          name="keeper-header-value"
          value={formState.headerValue}
          onChange={(e) => handleInputChange(e, 'SET_HEADER_VALUE')}
          placeholder="Header Value"
          style={{ marginBottom: '4px' }}
          type="text"
        />
        <button
          data-testid="keeper-debug-edit-button"
          style={{
            cursor: 'pointer',
          }}
          onClick={(e) => {
            handleFormSubmit(e);
          }}
        >
          Edit
        </button>
      </form>
    </>
  );
};

export default KeeperDebug;
