import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';

export class ScrollHandler implements IEventHandler {
  handler(
    source: Observable<Action<'scroll', any>>
  ): Observable<Action<'scroll'>> {
    return source.pipe();
  }
}
