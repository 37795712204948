import { Action } from './base.action';

export interface IHornetBase {
  type?: string;
}

export interface IBaseParams {
  children?: Array<IBaseElement>;
  child?: IBaseElement;
  hasState?: boolean;
  keepState?: boolean;
  action?: Action;
}

export interface IBaseElement<T = unknown> extends IHornetBase {
  key: string;
  type?: string;
  parameters?: IBaseParams & T;
}

export abstract class BaseComponent<T = unknown> implements IBaseElement<T> {
  key: string;
  type?: string;
  parameters?: IBaseParams & T;

  constructor(obj: IBaseElement<T>) {
    this.key = obj.key;
    this.type = obj.type;
    this.parameters = obj.parameters;
  }
}
