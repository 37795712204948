const getRootElement = (
  selector: string,
  baseRoot: any = window.document
): Element | null => {
  if (!selector || typeof selector !== 'string' || !baseRoot) return null;

  const selectorSanitized = `${selector.replace(/\./g, '\\.')}`;

  return baseRoot.querySelector(selectorSanitized) || null;
};

export default getRootElement;
