import { filter } from 'rxjs/operators';
import { Action } from '../base.action';

export function ofType<
  T extends string | ((arg: S) => boolean),
  S extends Action
>(...allowedTypes: Array<T>) {
  return filter((action: S) => {
    return allowedTypes.every((typeOrActionCreator: any) => {
      if (typeOrActionCreator instanceof Function) {
        return typeOrActionCreator(action as S);
      }

      return typeOrActionCreator === action.type;
    });
  });
}
