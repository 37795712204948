/* eslint-disable react/jsx-no-useless-fragment */
import { BaseComponent, BaseContext } from '@bees-lite-web/core';
import { memo, useCallback, useRef } from 'react';
import { mapper } from './elements/component-mapper';
import { FallbackComponent } from './elements/components';
import { emmiter } from './events/eventContext';

type Props = {
  children?: unknown;
  json: any;
  contextData: {
    [key: string]: BaseContext;
  };
  $replacement?: boolean;
  formatPropsName?: boolean;
};

export const generateUuid = () => {
  const a = new Uint32Array(3);
  window.crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '');
};

const generateChildKey = (key: string) => {
  return `Parser-${key ?? generateUuid()}`;
};

const prevRerenderHandler = (renderId: string): boolean => {
  const prevRerenderStorageKey = 'prevRerendered';
  let singleRenderItems: string | string[] =
    sessionStorage.getItem(prevRerenderStorageKey) ?? '';

  if (singleRenderItems) {
    singleRenderItems = singleRenderItems.split(':');
  }

  if (
    Array.isArray(singleRenderItems) &&
    singleRenderItems.includes(renderId)
  ) {
    return true;
  }

  setTimeout(() => {
    const newRerenderedItems = `${singleRenderItems}:${renderId}`.replace(
      /,/g,
      ':'
    );
    sessionStorage.setItem(prevRerenderStorageKey, newRerenderedItems);
  }, 500);
  return false;
};

export const ObjectParser = memo((props: Props) => {
  const {
    type,
    parameters,
    key = generateUuid(),
  } = props.json ?? ({} as BaseComponent);
  const keyRef = useRef(key);
  const { current: currentKey } = keyRef;

  const { contextData, formatPropsName } = props;

  const { child, children, ...params } = parameters ?? {};

  const InnerComponent = (mapper[type] ??
    FallbackComponent) as React.ElementType;

  let Component = InnerComponent;

  if (!props.$replacement && parameters?.nodeId) {
    Component = ({ children: ch, ...props }) => (
      <div node-id={parameters.nodeId} style={{ display: 'contents' }}>
        <InnerComponent {...props}>{ch}</InnerComponent>
      </div>
    );
  }

  const handle = useCallback((evt) => emmiter.next(evt.detail), []);

  if (!Component) return null;

  if (!type) {
    return <></>;
  }

  if (params.renderOnce && prevRerenderHandler(params.renderId)) {
    return <></>;
  }

  if (type === 'page') {
    const { body, appBar, bottomBar, sidebar, ...rest } =
      props.json ?? ({} as BaseComponent);
    return (
      <Component
        {...rest}
        id={currentKey}
        key={currentKey}
        {...{ onHandle: handle }}
      >
        <div slot="appBar" style={{ display: 'contents' }}>
          {appBar && (
            <ObjectParser
              contextData={contextData}
              key="appBar"
              json={appBar}
            />
          )}
        </div>
        <div slot="sidebar" style={{ display: 'contents' }}>
          {sidebar && (
            <ObjectParser
              contextData={contextData}
              key="sidebar"
              json={sidebar}
            />
          )}
        </div>
        <div
          slot="body"
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          {body &&
            body.map((child: BaseComponent, index: number) => (
              <ObjectParser
                contextData={contextData}
                key={`body-${index}`}
                json={child}
              />
            ))}
        </div>
        <div slot="bottomBar" style={{ display: 'contents' }}>
          {bottomBar && (
            <ObjectParser
              contextData={contextData}
              key="bottomBar"
              json={bottomBar}
            />
          )}
        </div>
      </Component>
    );
  }

  function convertPropsToLowerCase(obj: any) {
    const newObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const kebabCaseKey = key
          .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
          .toLowerCase();
        newObj[kebabCaseKey] = obj[key];
      }
    }
    return newObj;
  }

  return (
    <Component
      key={currentKey}
      id={currentKey}
      {...{
        ...((params.stateId && contextData[params.stateId]?.data) ??
          (formatPropsName ? convertPropsToLowerCase(params) : params) ??
          {}),
      }}
      {...{ onHandle: handle }}
    >
      {children ? (
        children
          ?.filter(Boolean)
          ?.map((child: BaseComponent, index: number) => {
            if (Array.isArray(child)) {
              return child
                .filter(Boolean)
                .map((innerChild: BaseComponent) => (
                  <ObjectParser
                    contextData={contextData}
                    key={`${generateChildKey(innerChild.key)}-${index}`}
                    json={innerChild}
                    formatPropsName={formatPropsName}
                  />
                ));
            }

            const _key = `${generateChildKey(child.key)}-${index}`;

            return (
              <ObjectParser
                contextData={contextData}
                key={_key}
                json={child}
                formatPropsName={formatPropsName}
              />
            );
          })
      ) : child ? (
        <ObjectParser
          contextData={contextData}
          key={generateChildKey(child.key)}
          json={child}
          formatPropsName={formatPropsName}
        />
      ) : null}
    </Component>
  );
});

// for debug propose on react dev tools
ObjectParser.displayName = 'ObjectParser';
