import { Action, BaseComponent, Reaction } from '@bees-lite-web/core';
import { DriveStep } from 'driver.js';

export interface KeeperActionPartialUpdates {
  nodeId: string;
  widget: BaseComponent;
  updatePayload?: boolean;
}

export interface KeeperAction {
  cleanAllCache?: boolean;
  refreshToken?: boolean;
  save?: Record<string, any>;
  sessionSave?: Record<string, any>;
  goTo?: string;
  reload?: boolean;
  message?: string;
  reactions?: Reaction[];
  partialUpdates?: KeeperActionPartialUpdates[];
  appTour?: AppTour;
  logout?: boolean;
  notification?: any;
  connections?: Connection[];
}

export interface KeeperPayload {
  action?: KeeperAction;
  lifeTime: number;
  render: BaseComponent[];
  responseCode: number;
  version: string;
}

export interface AppTour {
  config: AppTourConfig;
  steps: DriveStep[];
  popoverStyles?: AppTourPopoverStyles;
  key: string;
  version: string;
  alreadyShowAfterClosed?: boolean;
}

export interface AppTourPopoverStyles {
  nextButton?: CSSStyleDeclaration;
  previousButton?: CSSStyleDeclaration;
  footerButtons?: CSSStyleDeclaration;
  arrow?: CSSStyleDeclaration;
  closeButton?: CSSStyleDeclaration;
  description?: CSSStyleDeclaration;
  footer?: CSSStyleDeclaration;
  progress?: CSSStyleDeclaration;
  title?: CSSStyleDeclaration;
  wrapper?: CSSStyleDeclaration;
}

export interface AppTourConfig {
  delay?: number;
  animate: boolean;
  nextBtnText: string;
  doneBtnText: string;
  prevBtnText: string;
  stageRadius: number;
  overlayColor: string;
  showProgress: boolean;
  onPopoverRender?: (popover: any) => void;
}

export interface AppTourStep {
  id: string;
  element?: Element | AppTourElement | null;
  popover: {
    title: string;
    description: string;
  };
}

export interface AppTourElement {
  selectorName: string;
  selectorValue: string;
}
export interface Connection {
  connectionId: string;
  connectionType: ConnectionType;
  connectionString: string;
  connectionParameters: SocketParameters | SSEParameters;
}

export type ConnectionType = 'SOCKET' | 'SSE';

export enum ConnectionTypeEnum {
  SOCKET = 'SOCKET',
  SSE = 'SSE',
}

export interface SocketParameters {
  events: SocketEvent[];
}

export interface SocketEvent {
  socketEventType: SocketEventType;
  eventName: string;
  data: any;
  action: Action;
}

type SocketEventType = 'emit' | 'listen';

export interface SSEParameters {}
