import { ExpressionModel } from './expressionModel';

export class Reaction {
  targetId: string;
  path: string;
  expression: ExpressionModel;

  constructor(targetId: string, path: string, expression: ExpressionModel) {
    this.targetId = targetId;
    this.path = path;
    this.expression = expression;
  }
}
