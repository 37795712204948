export class BaseContext {
  id: string;
  keepState: boolean;
  data: { [key: string]: any };

  constructor(id: string, data: { [key: string]: any }, keepState = false) {
    this.id = id;
    this.keepState = keepState;

    this.data = data;
  }
}
