import { useEffect } from 'react';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { emmiter, MELLIFERA_EVENT_TYPE } from '../../core/eventContext';
import { Action } from '@bees-lite-web/core';

export const LoginScreen = () => {
  useEffect(() => {
    (async () => {
      emmiter.next(
        new Action({
          type: MELLIFERA_EVENT_TYPE.navigation,
          url: ActionsUrl.LOGIN,
        })
      );
    })();
  }, []);

  return null;
};
