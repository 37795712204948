import { Action, IEventHandler } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { KeeperAbstractService } from '../../services/keeper/keeper.abstract.service';
import { getValueByPath } from '../../store/utils/getValueByPath';

const getParameters = (parameters: any) => {
  if (!parameters?.getByStorage) return parameters;
  if (parameters.getByStorage) {
    const _parameters = { ...parameters };
    const getByStorage = parameters.getByStorage;
    delete _parameters.getByStorage;

    const complement = getValueByPath(
      JSON.parse(localStorage.getItem(getByStorage.item) as string),
      getByStorage.path
    );

    return { ..._parameters, [getByStorage.path]: complement };
  } else {
    return parameters;
  }
};

export class PostHandler implements IEventHandler {
  constructor(private bffService: KeeperAbstractService) {}

  handler(source: Observable<Action<'post', any>>): Observable<Action<'post'>> {
    return source.pipe(
      concatMap(async (e) => {
        if (e.url === 'exclusivePartial') return e;
        await this.bffService.post(e.url!, getParameters(e.parameters));
        return e;
      })
    );
  }
}
