import { DriveStep, PopoverDOM, driver } from 'driver.js';
import {
  AppTour, AppTourPopoverStyles
} from '../../../services/keeper/keeper.payload.interface';
import getElement from './getElement';

const getElements = (steps: any) =>
  new Promise<any>((resolve, reject) => {
    const elements: any = {};

    while (Object.keys(elements)?.length !== steps?.length) {
      for (const step of steps) {
        const elementKey = `${step.element.selectorName}-${step.element.selectorValue}`;

        if (!elements[elementKey]) {
          const element = getElement(step.element);
          elements[elementKey] = element;
        }
      }

      if (Object.keys(elements)?.length === steps?.length) {
        resolve(elements);
      }
    }

    setTimeout(() => {
      reject('Elements not found!');
    }, 12000);
  });

const appTour = async (payload: AppTour) =>
  new Promise<any>(async (resolve, reject) => {
    const { config, steps, popoverStyles, key, version } = payload;

    if (!config || !steps?.length) reject('Invalid app tour!');

    const elements = await getElements(steps);
    let _steps: any[] = [];

    if (Object.keys(elements)?.length === steps?.length) {
      _steps = steps?.map((step) => ({
        ...step,
        element: elements[step?.element as string],
      }));
    }

    if (_steps?.length) {
      const defaultState = {
        startDate: new Date().toISOString(),
        activeIndex: 0,
        finished: false,
        key,
        version,
        steps: steps.length - 1,
      };

      const defaultStateStringfied = JSON.stringify(defaultState);

      const dataStorageName = 'appTour';
      const driverTour = driver({
        ...config,
        onHighlightStarted: () => {
          localStorage.setItem(dataStorageName, defaultStateStringfied);
        },

        onNextClick: (_: any, __: DriveStep, options: any) => {
          const state = JSON.parse(
            localStorage.getItem(dataStorageName) || defaultStateStringfied
          );

          if (state?.finished) return;

          if (
            state?.activeIndex !== options.state.activeIndex &&
            options.state.activeIndex < steps.length - 1
          ) {
            localStorage.setItem(
              dataStorageName,
              JSON.stringify({
                ...state,
                activeIndex: options.state.activeIndex,
              })
            );
          } else if (options.state.activeIndex >= state.steps - 1) {
            localStorage.setItem(
              dataStorageName,
              JSON.stringify({
                ...state,
                finished: true,
                activeIndex: options.state.activeIndex,
                endDate: new Date().toISOString(),
              })
            );
          }

          if (options.state.activeIndex - 1 >= state.steps - 1)
            driverTour.destroy();

          driverTour.moveNext();
        },

        onCloseClick: (_: any, __: DriveStep, options: any) => {
          const state = JSON.parse(
            localStorage.getItem(dataStorageName) || defaultStateStringfied
          );

          if (state?.finished) return;

          if (
            state?.activeIndex !== options.state.activeIndex &&
            options.state.activeIndex < steps.length - 1
          ) {
            localStorage.setItem(
              dataStorageName,
              JSON.stringify({
                ...state,
                activeIndex: options.state.activeIndex,
                finished: !!payload.alreadyShowAfterClosed,
              })
            );
          } else if (options.state.activeIndex >= state.steps - 1) {
            localStorage.setItem(
              dataStorageName,
              JSON.stringify({
                ...state,
                finished: true,
                activeIndex: options.state.activeIndex,
                endDate: new Date().toISOString(),
              })
            );
          }
          driverTour.destroy();
        },

        onDestroyStarted: (_: any, __: DriveStep, options: any) => {
          const state = JSON.parse(
            localStorage.getItem(dataStorageName) || defaultStateStringfied
          );

          if (state?.finished) return;

          if (
            state?.activeIndex !== options.state.activeIndex &&
            options.state.activeIndex < steps.length - 1
          ) {
            localStorage.setItem(
              dataStorageName,
              JSON.stringify({
                ...state,
                activeIndex: options.state.activeIndex,
              })
            );
          } else if (options.state.activeIndex >= state.steps - 1) {
            localStorage.setItem(
              dataStorageName,
              JSON.stringify({
                ...state,
                finished: true,
                activeIndex: options.state.activeIndex,
                endDate: new Date().toISOString(),
              })
            );
          }
          driverTour.destroy();
        },

        onPopoverRender: (popover: PopoverDOM) => {
          if (Object.keys(popoverStyles || {})?.length)
            for (const element of Object.keys(popoverStyles || {})) {
              const styles = popoverStyles?.[element as keyof AppTourPopoverStyles]
              
              if (popover[element as keyof PopoverDOM] && Object.entries(styles || {}).length > 0) {
                for (const [key, value] of Object.entries(styles || {})) 
                  popover[element as keyof PopoverDOM].style[key as any] = value
              }
            }
        },

        steps: steps?.map((step) => {
          return {
            ...step,
            element: getElement(step?.element as string),
          };
        }),
      });

      resolve(driverTour);
    } else {
      reject('Elements not found!');
    }
  });

export default appTour;
