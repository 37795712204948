import { BaseContext, Reaction } from '@bees-lite-web/core';
import AppState from '../State';
import { setKeepedState } from '../utils/setKeepedState';
import { setValueToPath } from '../utils/setValueToPath';

export const onUpdateContextDataSetOperation = (
  state: AppState,
  reaction: Reaction
) => {
  const newData: {
    [key: string]: BaseContext;
  } = { ...state.data };

  if (!newData[reaction.targetId]?.data) return state;

  setValueToPath(
    newData[reaction.targetId].data,
    reaction.expression.value,
    `${reaction.path}`
  );

  const newState: AppState = new AppState(newData);

  if (newState.data[reaction.targetId].keepState) {
    setKeepedState(newState.data[reaction.targetId]);
  }

  return newState;
};
