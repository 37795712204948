import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionsUrl } from '../../core/actionUrl.shortcut';
import { emmiter } from '../../core/eventContext';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export class ResponseActionsReloadHandler
  implements IEventHandler<KeeperPayload>
{
  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType(
        (e) =>
          !!e.parameters &&
          !!e.parameters.action &&
          !!e.parameters.action.reload
      ),
      tap(async (e) => {
        emmiter.next(
          new Action({
            url: ActionsUrl.UPDATE_PAGE_PARAMETERS,
            parameters: {
              _t: Date.now(),
            },
          })
        );
      })
    );
  }
}
